import { Howl } from 'howler';
import * as PIXI from 'pixi.js';
import { BehaviorSubject } from 'rxjs';

type TutorialStep = {
    texture: string,
    button: {
        x: number,
        y: number,
        w: number,
        h: number
    }
}

export default class CookingTutorial extends PIXI.Container {
    private steps: TutorialStep[] = [
        {
            texture: 'cooking/tutorial/1',
            button: {

                x: 1476,
                y: 681,
                w: 336,
                h: 64
            }
        },
        {
            texture: 'cooking/tutorial/2',
            button: {

                x: 939,
                y: 825,
                w: 336,
                h: 64
            }
        },
        {
            texture: 'cooking/tutorial/3',
            button: {

                x: 645,
                y: 529,
                w: 336,
                h: 64
            }
        },
        {
            texture: 'cooking/tutorial/4',
            button: {

                x: 1394,
                y: 770,
                w: 336,
                h: 64
            }
        },
        // {
        //     texture: 'cooking/tutorial/5',
        //     button: {

        //         x: 795,
        //         y: 514,
        //         w: 336,
        //         h: 64
        //     }
        // },
        {
            texture: 'cooking/tutorial/6',
            button: {

                x: 182,
                y: 707,
                w: 336,
                h: 64
            }
        },
        {
            texture: 'cooking/tutorial/7',
            button: {
                x: 792,
                y: 560,
                w: 336,
                h: 64
            }
        }
    ]

    constructor(private audio: Howl) {
        super();

        let step = new BehaviorSubject<number>(0);

        const tutSprite = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        tutSprite.interactive = true;
        this.addChild(tutSprite);

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        button.interactive = true;
        button.cursor = 'pointer';

        this.addChild(button);

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            if (step.value >= this.steps.length-1) {
                this.emit('end');
            } else {
                step.next(step.value + 1);
            }

        })

        step.subscribe((step) => {
            const stepData = this.steps[step];

            if (stepData) {
                tutSprite.texture = PIXI.Texture.from(stepData.texture);

                const buttonData = stepData.button;

                if (buttonData) {
                    button.position.set(buttonData.x, buttonData.y);
                    button.width = buttonData.w;
                    button.height = buttonData.h;
                }
            }
        })
    }

    public next() {

    }
}
