import * as PIXI from 'pixi.js'

export default abstract class GameStage extends PIXI.Container {
    private assets: any[] = [];
    private loader = new PIXI.Loader();

    destroy() {
        this.deleteAssets();
    }

    protected loadAssets(assets: any[]) {

        this.assets = assets;

        return new Promise(resolve => {
            this.assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    private deleteAssets() {
        this.loader.reset();
        this.loader.destroy();
    }
}
