import React from 'react';
import { Link } from 'react-router-dom';
import { getGoalsRoute } from '../routes';

import './style.scss';

export default function HomePage() {
    return (
        <div className="home-page">
            <div className="frame-container">
                <div className="frame-container-2">
                    <div className="frame">
                        <div className="header"></div>

                        <div className="content">
                            <p>
                                Gra pokazuje prace restauracji. Gracze wcielają się w zespół kucharzy (do 10 osób), który musi wspólnie zrealizować wszystkie zamówienia i otrzymać tytuł najlepszej restauracji w mieście.
                            </p>

                            <p>
                                W trakcie rozgrywki postępy poszczególnych drużyn są porównywane w rankingu.<br />
                                Gra pozwala na miłe, wspólne spędzenie czasu, lekką rozrywkę oraz ćwiczenie współpracy. Obsługa stanowisk i całego procesu produkcji wymaga sprawnej komunikacji.
                            </p>
                        </div>

                        <div className="footer">
                            <Link to={getGoalsRoute()}><span>Przejdź</span> <span>dalej</span></Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
