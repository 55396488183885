import React from 'react';
import { Link } from 'react-router-dom';
import { getRulesRoute } from '../routes';
import './style.scss';

export default function GoalsPage() {
    return (

        <div className="goals-page">
            <div className="frame-container">
                <div className="frame-container-2">
                    <div className="frame">
                        <div className="header">
                            <h1>Cele demo</h1>
                        </div>

                        <div className="content">
                            <p className="with-separator">
                                Praca zdalnych zespołów to znak naszych czasów. Razem ze zmieniającymi się potrzebami pojawiają się coraz lepsze pomysły
                                na to jak zadbać o potrzeby jednostek
                                i grupy pracujących w tym modelu.
                            </p>
                            <p>Zapraszamy do zapoznania się z możliwościami jednego z nich - gry integracyjnej Mistrzowie Restauracji.</p>
                            <p>Niniejsze demo prezentuje główne założenia gry. Odkryj je razem z nami.</p>
                        </div>

                        <div className="footer">
                            <Link to={getRulesRoute()}><span>Przejdź</span> <span>dalej</span></Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
