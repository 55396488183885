import * as PIXI from 'pixi.js';
import { Food } from '../type';

export default class FoodPreview extends PIXI.Container {

    private text = new PIXI.Text('');
    private sprite = new PIXI.Sprite();
    constructor() {
        super();

        // this.addChild(this.text);

        this.sprite.anchor.set(.5);
        this.addChild(this.sprite);
    }

    public setFood(food: Food) {
        this.text.text = food.name;

        switch (food.id) {
            case 'warzywa':
                this.sprite.texture = PIXI.Texture.from('warzywa-preview');
                break;
            case 'kurczak':
                this.sprite.texture = PIXI.Texture.from('kurczak-preview');
                break;
            case 'stek':
                this.sprite.texture = PIXI.Texture.from('stek-preview');
                break;
            case 'zupa':
                this.sprite.texture = PIXI.Texture.from('zupa-preview');
                break;
            case 'sos':
                this.sprite.texture = PIXI.Texture.from('sos-preview');
                break;
        }
    }
}
