import * as PIXI from 'pixi.js';

export default class FireControll extends PIXI.Container {
    private sprite = PIXI.Sprite.from('fire-controll');

    constructor() {
        super();

        this.sprite.anchor.set(.5);
        this.sprite.position.set(70, 70);

        this.addChild(this.sprite);
    }

    setValue(value: 0 | 0.5 | 1) {
        this.sprite.rotation = Math.PI * value;
    }
}
