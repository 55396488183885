import * as PIXI from 'pixi.js'
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import GameStage from '../game-stage';
import KitchenDataStorage from './data-storage';
import Plate from './game-objects/plate';
import FireControll from './game-objects/fire-controll';
import FireSpot from './game-objects/fire-spot';
import FoodListItem from './game-objects/food-list-item';
import OrderList from './game-objects/order-list';
import FlipControll from './game-objects/rotate-controll';
import ShakeControll from './game-objects/shake-controll';
import { getFoodMenuItemLayoutData } from './layout-data';
import { initCookingSystem, initOrderReceiverSystem, initOrderSenderSystem } from './systems';
import { USER_STATE, Tool, Food } from './type';
import FlipPreview from './previews/flip-preview';
import ShakePreview from './previews/shake-preview';
import ComboPreview from './previews/combo-preview';
import FoodPreview from './previews/food-preview';
import ToolPreview from './previews/tool-preview';
import UserAvatar from '../../components/user-avatar';
import { UserData } from '../..';
import ReadyFoodPreview from './previews/ready-food-preview';
import CookingIntroPopup from './intro-popup';
import { Howl } from 'howler';
import CookingTutorial from './tutorial';
import ToolList from './game-objects/tool-list';

export default class Kitchen extends GameStage {

    gameClock = interval(1000);

    private data = new KitchenDataStorage();

    private receiverSystem = initOrderReceiverSystem(this.data, this.gameClock)

    private phase = new BehaviorSubject<'intro' | 'tutorial' | 'game'>('intro');


    private shakePreview?: ShakePreview;
    private flipPreview?: FlipPreview;
    private toolPreview?: ToolPreview;
    private foodPreview?: FoodPreview;
    private comboPreview?: ComboPreview;

    private readyFoodPreview?: ReadyFoodPreview;

    private wrongPopup = new PIXI.Container();
    private correctPopup = new PIXI.Container();

    constructor(private userData: BehaviorSubject<UserData | null>, private audio: Howl) {
        super();

        this.sortableChildren = true;


        this.loadAssets([
            ['cooking-spritesheet-0', '/assets/cooking/cooking-0.json'],
            ['cooking-spritesheet-1', '/assets/cooking/cooking-1.json']
        ]).then(() => {

            this.initBackground();

            this.initToolMenu();
            this.initFoodMenu();

            // this.initDebug();

            this.initButtons();

            this.initFireSpots();
            this.initFireControlls();


            this.initShakeTool();
            this.initFlipTool();

            this.initPlates();
            this.initOrderList();

            this.interactive = true;
            this.on('pointerup', () => {
                this.data.userState.next(USER_STATE.IDLE);
            });
            this.on('pointerupoutside', () => {
                this.data.userState.next(USER_STATE.IDLE);
            });



            initCookingSystem(this.data, this.gameClock);
            initOrderSenderSystem(this.data, this.gameClock);

            this.initAvatar();

            this.initTrash();

            this.initTutorial();
            this.initIntroPopup();

            this.initCorrectPopup();
            this.initWrongPopup();

            this.initMoovingPreviews();
        })
    }

    private initBackground() {
        const sprite = PIXI.Sprite.from('cooking/full');
        this.addChild(sprite);
    }

    private initDebug() {
        const text = new PIXI.Text('');

        this.addChild(text);

        this.data.userState.subscribe((value) => {
            text.text = 'UserState: ' + value;
        })
    }

    private initToolMenu() {
        const toolList = new ToolList(this.data.toolsData, {
            onToolItemDragStart: this.onToolItemDragStart
        })

        toolList.interactive = true;

        toolList.on('pointerup', () => {
            if (this.data.userState.value === USER_STATE.MOVING_TOOL) {
                this.emptyCookingSpot(this.data.movingToolSource.value || '');
                this.data.userState.next(USER_STATE.IDLE);
                this.data.movingTool.next(null);
            }
        })

        this.addChild(toolList);
    }

    private initFoodMenu() {

        this.data.foodData.forEach(foodData => {
            const { x, y, width, height } = getFoodMenuItemLayoutData(foodData.id);

            const foodListItem = new FoodListItem(foodData, width, height);

            foodListItem.position.set(x, y);
            foodListItem.interactive = true;
            foodListItem.on('pointerdown', () => this.onFoodItemDragStart(foodData))

            this.addChild(foodListItem);
        });
    }

    private initToolPreview() {
        this.data.userState.subscribe(value => {
            if (this.toolPreview) {
                this.toolPreview.visible = value === USER_STATE.MOVING_TOOL;
            }
        })

        this.data.movingTool.subscribe(value => {
            if (this.toolPreview && value) {
                this.toolPreview.setTool(value);
            }
        })

        this.data.movingToolSource.subscribe(value => {
            if (this.toolPreview) {
                if (!value) {
                    this.toolPreview.scale.set(1, 1);
                    return;
                }

                const flip = ['spot2', 'spot4'].includes(value);

                this.toolPreview.scale.set(flip ? -1 : 1, 1);

            }
        })

        this.toolPreview = new ToolPreview();
        this.toolPreview.visible = false;

        this.addChild(this.toolPreview);
    }

    private initFoodPreview() {
        this.data.userState.subscribe(value => {
            if (this.foodPreview) {
                this.foodPreview.visible = value === USER_STATE.MOVING_FOOD;
            }
        })

        this.data.movingFood.subscribe(value => {
            if (this.foodPreview && value) {
                this.foodPreview.setFood(value);
            }
        })

        this.foodPreview = new FoodPreview();
        this.foodPreview.visible = false;

        this.addChild(this.foodPreview);
    }

    private initReadyFoodPreview() {
        this.data.userState.subscribe(value => {
            if (this.readyFoodPreview) {
                this.readyFoodPreview.visible = value === USER_STATE.MOVING_READY_FOOD;
            }
        })

        this.data.movingFood.subscribe(value => {
            if (this.readyFoodPreview && value) {
                this.readyFoodPreview.setFood(value);
            }
        })

        this.readyFoodPreview = new ReadyFoodPreview();
        this.readyFoodPreview.visible = false;

        this.addChild(this.readyFoodPreview);
    }

    private initComboPreview() {
        this.data.userState.subscribe(value => {
            if (this.comboPreview) {
                this.comboPreview.visible = value === USER_STATE.MOVING_COMBO;
            }
        })

        this.data.movingCombo.subscribe(value => {
            if (this.comboPreview && value) {
                this.comboPreview.setToolFood(value.food, value.tool, value.complete);
            }
        })

        this.comboPreview = new ComboPreview(
            this.data.movingComboSource.pipe(map(id => {
                return ['spot2', 'spot4'].includes(id || '');
            })),
            this.data.movingCombo.pipe(map(combo => {
                if (!combo) return false;

                return combo.side % 2 === 1;
            }))
        );

        this.comboPreview.visible = false;



        this.addChild(this.comboPreview);
    }

    private initShakePreview() {
        this.data.userState.subscribe(value => {
            if (this.shakePreview) {
                this.shakePreview.visible = value === USER_STATE.USING_SHAKE_TOOL;
            }
        })

        this.shakePreview = new ShakePreview();
        this.shakePreview.visible = false;

        this.addChild(this.shakePreview);
    }

    private initFlipPreview() {
        this.data.userState.subscribe(value => {
            if (this.flipPreview) {
                this.flipPreview.visible = value === USER_STATE.USING_FLIP_TOOL;
            }
        })
        this.flipPreview = new FlipPreview();
        this.flipPreview.visible = false;
        this.addChild(this.flipPreview);
    }

    private initMoovingPreviews() {
        this.initToolPreview();
        this.initFoodPreview();
        this.initReadyFoodPreview();
        this.initComboPreview();
        this.initShakePreview();
        this.initFlipPreview();


        this.data.userState.subscribe(state => {
            if (state === USER_STATE.IDLE) {
                this.data.movingComboSource.next(null);
                this.data.movingToolSource.next(null);
                this.data.movingReadyFoodSource.next(null);
            }
        })

        this.on('pointermove', (event: PIXI.InteractionEvent) => {
            const newPosition = event.data.getLocalPosition(this);
            if (this.toolPreview) {
                this.toolPreview.position.set(newPosition.x, newPosition.y);
            }

            if (this.foodPreview) {
                this.foodPreview.position.set(newPosition.x, newPosition.y);
            }

            if (this.readyFoodPreview) {
                this.readyFoodPreview.position.set(newPosition.x, newPosition.y);
            }

            if (this.comboPreview) {
                this.comboPreview.position.set(newPosition.x, newPosition.y);
            }

            if (this.shakePreview) {
                this.shakePreview.position.set(newPosition.x, newPosition.y);
            }

            if (this.flipPreview) {
                this.flipPreview.position.set(newPosition.x, newPosition.y);
            }
        })
    }

    private startMoovigTool(tool: Tool) {
        this.data.userState.next(USER_STATE.MOVING_TOOL);
        this.data.movingTool.next(tool);
    }

    private startMoovigFood(food: Food) {
        this.data.userState.next(USER_STATE.MOVING_FOOD);
        this.data.movingFood.next(food);
    }

    private startMoovigReadyFood(food: Food) {
        this.data.userState.next(USER_STATE.MOVING_READY_FOOD);
        this.data.movingFood.next(food);
    }

    private onToolItemDragStart = (tool: Tool) => {
        if (this.data.userState.value === USER_STATE.IDLE) {
            this.startMoovigTool(tool);
        }
    }

    private onFoodItemDragStart(food: Food) {
        if (this.data.userState.value === USER_STATE.IDLE) {
            this.startMoovigFood(food);
        }
    }

    private onReadyFoodDragStart(food: Food) {
        if (this.data.userState.value === USER_STATE.IDLE) {
            this.startMoovigReadyFood(food);
        }
    }

    private initFireSpots() {
        this.data.cookingSpotsData.forEach((spotData, index) => {

            const {
                x,
                y,
                toolData,
                id,
                foodData,
                completeData,
                shakeTime,
                cookingModel,
                activeSideData,
                fireData,
                size
            } = spotData;

            const showContent = combineLatest([
                this.data.movingComboSource.pipe(map(s => s !== id)),
                this.data.movingToolSource.pipe(map(s => s !== id))
            ]).pipe(
                map(([combo, tool]) => {
                    return Boolean(tool && combo)
                })
            )

            fireData.subscribe(value => {
                if (value === .5) {
                    this.audio.play('oven-on');
                }
            })

            const spot = new FireSpot({
                complete: completeData,
                shaking: shakeTime.pipe(
                    map(time => time > 0)
                ),
                tool: toolData,
                food: foodData,
                cookingModel,
                isOn: fireData.pipe(map(f => f > 0)),
                size,
                showContent,
                flipTool: index % 2 === 1,
                flipFood: activeSideData.pipe(map(size => size % 2 === 1))
            });

            spot.position.set(x, y);
            spot.interactive = true;

            foodData.subscribe(food => {
                if (food) {
                    cookingModel.next(food?.cookingType)
                }
            })

            const stopDrag = () => {
                if (this.data.userState.value === USER_STATE.MOVING_TOOL && this.data.movingTool.value) {
                    if (toolData.value === null) {
                        toolData.next(this.data.movingTool.value);
                        this.emptyCookingSpot(this.data.movingToolSource.value || '');
                        activeSideData.next(0);
                    }
                }

                if (this.data.userState.value === USER_STATE.MOVING_FOOD && this.data.movingFood.value) {
                    if (toolData.value && foodData.value === null && this.data.movingFood.value.allowedTools.indexOf(toolData.value.id) >= 0) {
                        foodData.next(this.data.movingFood.value);
                        activeSideData.next(0);
                    }
                }

                if (this.data.userState.value === USER_STATE.MOVING_COMBO && this.data.movingCombo.value) {
                    if (toolData.value === null) {
                        foodData.next(this.data.movingCombo.value.food);
                        toolData.next(this.data.movingCombo.value.tool);
                        completeData.next(this.data.movingCombo.value.complete);
                        activeSideData.next(this.data.movingCombo.value.side);

                        this.emptyCookingSpot(this.data.movingComboSource.value || '');
                    }
                }

                if (this.data.userState.value === USER_STATE.USING_SHAKE_TOOL) {
                    if (toolData.value && foodData.value) {
                        shakeTime.next(5);
                    }
                }

                if (this.data.userState.value === USER_STATE.USING_FLIP_TOOL) {
                    if (toolData.value && foodData.value) {
                        const newSide = activeSideData.value + 1 > foodData.value.cookingType.sides - 1 ? 0 : activeSideData.value + 1;
                        activeSideData.next(newSide);
                    }
                }
            }

            const startDrag = () => {
                if (this.data.userState.value === USER_STATE.IDLE && toolData.value) {
                    if (foodData.value) {
                        this.data.movingComboSource.next(id);
                        this.data.userState.next(USER_STATE.MOVING_COMBO);
                        this.data.movingCombo.next({
                            food: foodData.value,
                            tool: toolData.value,
                            complete: completeData.value,
                            side: activeSideData.value
                        })

                    } else {
                        this.data.movingToolSource.next(id);
                        this.data.userState.next(USER_STATE.MOVING_TOOL);
                        this.data.movingTool.next(toolData.value);
                    }
                }
            }

            spot.on('pointerupoutside', stopDrag);
            spot.on('pointerup', stopDrag);
            spot.on('pointerdown', startDrag);

            this.addChild(spot);
        })

    }

    private initFireControlls() {

        this.data.fireControllData.forEach((data) => {
            const { x, y, fireData } = data;

            const controll = new FireControll();

            controll.position.set(x, y);
            controll.interactive = true;

            fireData.subscribe(value => {
                controll.setValue(value);
            })

            controll.on('click', () => {
                const oldValue = fireData.value;

                switch (oldValue) {
                    case 0:
                        fireData.next(0.5);
                        break;
                    case 0.5:
                        fireData.next(1);
                        break;
                    case 1:
                        fireData.next(0);
                        break;
                }
            })

            this.addChild(controll);
        })
    }

    private initPlates() {
        [this.data.plate1Data, this.data.plate2Data, this.data.plate3Data].forEach((data, index) => {

            const showContent = this.data.movingReadyFoodSource.pipe(map(v => v !== data.value.id));

            const plate = new Plate(data, showContent);

            plate.interactive = true;
            plate.position.set(740 + (index * 145), 35);

            const stopDrag = () => {
                if (this.data.userState.value === USER_STATE.MOVING_COMBO && this.data.movingCombo.value && !data.value.food) {
                    this.emptyCookingSpot(this.data.movingComboSource.value || '');

                    const result = this.receiverSystem.putFoodOnPlate(this.data.movingCombo.value.food, this.data.movingCombo.value.complete, data.value.id)

                    if (result) {
                        this.correctPopup.visible = true;
                    } else {
                        this.wrongPopup.visible = true;
                    }
                }
            }

            plate.on('pointerupoutside', stopDrag);
            plate.on('pointerup', stopDrag);

            plate.on('pointerdown', () => {
                if (data.value?.food) {
                    this.data.movingReadyFoodSource.next(data.value.id)
                    this.onReadyFoodDragStart(data.value.food)
                }
            })

            this.addChild(plate);
        })
    }

    private initOrderList() {
        const orderList = new OrderList(this.data.orders);

        orderList.position.set(1500, 210);

        this.addChild(orderList);
    }

    private emptyCookingSpot(id: string) {
        switch (id) {
            case 'spot1':
                this.data.spot1Tool.next(null);
                this.data.spot1Food.next(null);
                this.data.spot1Complete.next([0]);
                this.data.spot1ShakeTime.next(0);
                break;

            case 'spot2':
                this.data.spot2Tool.next(null);
                this.data.spot2Food.next(null);
                this.data.spot2Complete.next([0]);
                this.data.spot2ShakeTime.next(0);
                break;

            case 'spot3':
                this.data.spot3Tool.next(null);
                this.data.spot3Food.next(null);
                this.data.spot3Complete.next([0]);
                this.data.spot3ShakeTime.next(0);
                break;

            case 'spot4':
                this.data.spot4Tool.next(null);
                this.data.spot4Food.next(null);
                this.data.spot4Complete.next([0]);
                this.data.spot4ShakeTime.next(0);
                break;
        }
    }

    private emptyPlate(id: string) {
        switch (id) {
            case 'plate1':
                this.data.plate1Data.next({ id: 'plate1' })
                break;

            case 'plate2':
                this.data.plate2Data.next({ id: 'plate2' })
                break;

            case 'plate3':
                this.data.plate3Data.next({ id: 'plate3' })
                break;
        }
    }

    private initShakeTool() {
        const shakeTool = new ShakeControll();
        shakeTool.position.set(680, 780);
        shakeTool.interactive = true;

        const startDrag = () => {
            if (this.data.userState.value === USER_STATE.IDLE) {
                this.data.userState.next(USER_STATE.USING_SHAKE_TOOL);
            }
        }

        shakeTool.on('pointerdown', startDrag);

        this.addChild(shakeTool);
    }

    private initFlipTool() {
        const flipTool = new FlipControll();
        flipTool.position.set(1115, 780);
        flipTool.interactive = true;

        const startDrag = () => {
            if (this.data.userState.value === USER_STATE.IDLE) {
                this.data.userState.next(USER_STATE.USING_FLIP_TOOL);
            }
        }

        flipTool.on('pointerdown', startDrag);

        this.addChild(flipTool);
    }

    private initTutorial() {
        const tutorial = new CookingTutorial(this.audio);

        tutorial.on('end', () => {
            this.phase.next('game');
        })

        this.phase.subscribe(value => {
            tutorial.visible = value === 'tutorial';
        })

        this.addChild(tutorial);
    }

    private initAvatar() {
        const avatar = new UserAvatar(this.userData);

        avatar.position.set(205, 795);
        this.addChild(avatar);
    }

    private initButtons() {
        const back = PIXI.Sprite.from('button/back');

        back.interactive = true;
        back.cursor = 'pointer';
        back.on('pointerdown', () => {
            this.emit('go-back');
        });

        back.position.set(100, 978);

        this.addChild(back);
    }

    private initTrash() {
        const sprite = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        sprite.width = 100;
        sprite.height = 107;

        sprite.position.set(1234, 38);
        sprite.interactive = true;

        this.addChild(sprite);

        sprite.on('pointerup', () => {
            if (this.data.userState.value === USER_STATE.MOVING_COMBO) {
                this.emptyCookingSpot(this.data.movingComboSource.value || '');
            }
            if (this.data.userState.value === USER_STATE.MOVING_READY_FOOD) {
                this.emptyPlate(this.data.movingReadyFoodSource.value || '');
            }
        })
    }

    private initIntroPopup() {
        const introPopup = new CookingIntroPopup();
        this.addChild(introPopup);

        introPopup.on('close', () => {
            this.audio.play('on-click');
            this.phase.next('tutorial');
        })

        this.phase.subscribe(state => {
            introPopup.visible = state === 'intro';
        });
    }

    private initCorrectPopup() {
        const sprite = PIXI.Sprite.from('cooking/popup-correct');
        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        sprite.interactive = true;
        button.interactive = true;
        button.cursor = 'pointer';
        button.on('pointerdown', () => {
            this.audio.play('on-click');
            this.correctPopup.visible = false;
        });

        button.position.set(793, 449);
        button.width = 336;
        button.height = 64;

        this.correctPopup.visible = false;

        this.correctPopup.addChild(sprite);
        this.correctPopup.addChild(button);

        this.addChild(this.correctPopup);
    }

    private initWrongPopup() {
        const sprite = PIXI.Sprite.from('cooking/popup-wrong');
        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        sprite.interactive = true;
        button.interactive = true;
        button.cursor = 'pointer';
        button.on('pointerdown', () => {
            this.audio.play('on-click');
            this.wrongPopup.visible = false;
        });

        button.position.set(793, 449);
        button.width = 336;
        button.height = 64;

        this.wrongPopup.visible = false;

        this.wrongPopup.addChild(sprite);
        this.wrongPopup.addChild(button);

        this.addChild(this.wrongPopup);
    }
}



