import * as PIXI from 'pixi.js';

export default class OrderObject extends PIXI.Container {
    constructor(texture: PIXI.Texture) {
        super();

        const sprite = PIXI.Sprite.from('order');
        sprite.anchor.set(.5);

        const sprite2 = new PIXI.Sprite(texture);
        sprite2.anchor.set(.5);

        this.addChild(sprite);
        this.addChild(sprite2);
    }
}

