import * as PIXI from 'pixi.js';

export default class ShakePreview extends PIXI.Container {
    constructor() {
        super();

        const sprite = new PIXI.Sprite(PIXI.Texture.from('shake-tool'));
        sprite.anchor.set(.5);
        this.addChild(sprite);
    }
}
