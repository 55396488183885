import * as PIXI from 'pixi.js';
import { Observable } from 'rxjs';
import { Food, Tool } from '../type';

export default class ComboPreview extends PIXI.Container {


    private toolSprite = new PIXI.Sprite();
    private foodSprite = new PIXI.Sprite();

    private text = new PIXI.Text('');
    constructor(flipTool: Observable<boolean>, flipFood: Observable<boolean>) {
        super();


        this.addChild(this.toolSprite);
        this.addChild(this.foodSprite);

        flipTool.subscribe((value) => {
            this.toolSprite.scale.set(value ? -1 : 1, 1);
        });

        flipFood.subscribe((value) => {
            this.foodSprite.scale.set(value ? -1 : 1, 1);
        });

        this.foodSprite.anchor.set(.5);
    }

    public setToolFood(food: Food, tool: Tool, complete: number[]) {
        this.text.text = `${food.name} (${complete.join('/')})`;

        if (!tool) {
            this.toolSprite.texture = PIXI.Texture.EMPTY;
            this.foodSprite.texture = PIXI.Texture.EMPTY;
            return;
        }

        this.toolSprite.anchor.set(tool.texture.anchor.x, tool.texture.anchor.y)

        if (tool.id === 'bigPot') {
            this.foodSprite.texture = PIXI.Texture.EMPTY;

            if (!food) {
                this.toolSprite.texture = PIXI.Texture.from('big-pot-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'zupa') {
                this.toolSprite.texture = PIXI.Texture.from('big-pot-zupa') || PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'sos') {
                this.toolSprite.texture = PIXI.Texture.from('big-pot-sos') || PIXI.Texture.EMPTY;
                return;
            }
        }

        if (tool.id === 'smallPot') {
            this.foodSprite.texture = PIXI.Texture.EMPTY;

            if (!food) {
                this.toolSprite.texture = PIXI.Texture.from('small-pot-empty') || PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'zupa') {
                this.toolSprite.texture = PIXI.Texture.from('small-pot-zupa') || PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'sos') {
                this.toolSprite.texture = PIXI.Texture.from('small-pot-sos') || PIXI.Texture.EMPTY;
                return;
            }
        }

        if (tool.id === 'pan') {
            if (!food) {
                this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'warzywa') {
                this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('warzywa') || PIXI.Texture.EMPTY;
            }

            if (food.id === 'kurczak') {
                this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('kurczak') || PIXI.Texture.EMPTY;
            }

            if (food.id === 'stek') {
                this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('stek') || PIXI.Texture.EMPTY;
            }
        }

        if (tool.id === 'wok') {
            if (!food) {
                this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.EMPTY;
                return;
            }

            if (food.id === 'warzywa') {
                this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('warzywa') || PIXI.Texture.EMPTY;
            }

            if (food.id === 'kurczak') {
                this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('kurczak') || PIXI.Texture.EMPTY;
            }

            if (food.id === 'stek') {
                this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.from('stek') || PIXI.Texture.EMPTY;
            }
        }
    }
}
