import * as PIXI from 'pixi.js';
import { combineLatest, Observable } from 'rxjs';
import { Tool, Food, CookingModelData } from '../type';
import { CookingIndicator } from './cooking-inticator';

type Config = {
    complete: Observable<number[]>,
    shaking: Observable<boolean>,
    tool: Observable<Tool | null>,
    food: Observable<Food | null>,
    cookingModel: Observable<CookingModelData | null>,
    isOn: Observable<boolean>,
    size: 'big' | 'small',
    showContent: Observable<boolean>,
    flipTool: boolean,
    flipFood: Observable<boolean>
}

export default class FireSpot extends PIXI.Container {
    private shakingText = new PIXI.Text('Mieszanie!');

    private toolSprite = new PIXI.Sprite();
    private foodSprite = new PIXI.Sprite();

    constructor(
        private config: Config
    ) {
        super();

        this.initBaseSprite();

        this.addChild(this.toolSprite);
        this.addChild(this.foodSprite);

        if (this.config.flipTool) {
            this.toolSprite.scale.set(-1, 1);
        }

        this.foodSprite.anchor.set(.5);

        this.hitArea = new PIXI.Rectangle(-114, -114, 228, 228)

        this.config.shaking.subscribe(value => {
            this.shakingText.visible = value;
        })

        this.config.flipFood.subscribe(flip => {
            this.foodSprite.scale.set(flip ? -1 : 1, 1);
        })


        const indicator = new CookingIndicator(this.config.complete, this.config.cookingModel);
        indicator.position.set(0, -130);

        combineLatest([
            this.config.tool,
            this.config.food,
            this.config.showContent
        ]).subscribe(([tool, food, showContent]) => {

            if (!tool || !showContent) {
                this.toolSprite.texture = PIXI.Texture.EMPTY;
                this.foodSprite.texture = PIXI.Texture.EMPTY;
                return;
            }

            this.toolSprite.anchor.set(tool.texture.anchor.x, tool.texture.anchor.y)

            if (tool.id === 'bigPot') {
                this.foodSprite.texture = PIXI.Texture.EMPTY;

                if (!food) {
                    this.toolSprite.texture = PIXI.Texture.from('big-pot-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'zupa') {
                    this.toolSprite.texture = PIXI.Texture.from('big-pot-zupa') || PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'sos') {
                    this.toolSprite.texture = PIXI.Texture.from('big-pot-sos') || PIXI.Texture.EMPTY;
                    return;
                }
            }

            if (tool.id === 'smallPot') {
                this.foodSprite.texture = PIXI.Texture.EMPTY;

                if (!food) {
                    this.toolSprite.texture = PIXI.Texture.from('small-pot-empty') || PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'zupa') {
                    this.toolSprite.texture = PIXI.Texture.from('small-pot-zupa') || PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'sos') {
                    this.toolSprite.texture = PIXI.Texture.from('small-pot-sos') || PIXI.Texture.EMPTY;
                    return;
                }
            }

            if (tool.id === 'pan') {
                if (!food) {
                    this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'warzywa') {
                    this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('warzywa') || PIXI.Texture.EMPTY;
                }

                if (food.id === 'kurczak') {
                    this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('kurczak') || PIXI.Texture.EMPTY;
                }

                if (food.id === 'stek') {
                    this.toolSprite.texture = PIXI.Texture.from('pan-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('stek') || PIXI.Texture.EMPTY;
                }
            }

            if (tool.id === 'wok') {
                if (!food) {
                    this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.EMPTY;
                    return;
                }

                if (food.id === 'warzywa') {
                    this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('warzywa') || PIXI.Texture.EMPTY;
                }

                if (food.id === 'kurczak') {
                    this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('kurczak') || PIXI.Texture.EMPTY;
                }

                if (food.id === 'stek') {
                    this.toolSprite.texture = PIXI.Texture.from('wok-empty') || PIXI.Texture.EMPTY;
                    this.foodSprite.texture = PIXI.Texture.from('stek') || PIXI.Texture.EMPTY;
                }
            }
        })


        this.addChild(indicator);

        combineLatest([
            this.config.tool,
            this.config.food,
            this.config.showContent
        ]).subscribe(([tool, food, showContent]) => {
            indicator.visible = Boolean(food && tool && showContent)
        })
    }

    private initBaseSprite() {
        const sprite = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        sprite.anchor.set(.5);
        this.addChild(sprite);

        this.config.isOn.subscribe(value => {
            if (value) {
                sprite.texture = PIXI.Texture.from(`cooking/fire-place-${this.config.size}-on`);
            } else {
                sprite.texture = PIXI.Texture.from(`cooking/fire-place-${this.config.size}`);
            }
        })
    }
}




