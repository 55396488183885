import * as PIXI from 'pixi.js';
import GameStage from '../game-stage';

export default class GameEndScreen extends GameStage {
    constructor() {
        super();

        this.loadAssets([
            ['end', '/assets/end/end.json']
        ]).then(() => {
            const sprite = PIXI.Sprite.from('end/full');
            this.addChild(sprite);

            const button1 = new PIXI.Sprite(PIXI.Texture.EMPTY);

            button1.width = 234;
            button1.height = 42;

            button1.position.set(841, 297);

            button1.cursor = 'pointer'
            button1.interactive = true;
            button1.on('pointerdown', () => {
                this.emit('go-to-next');
            });

            this.addChild(button1);

            const button2 = new PIXI.Sprite(PIXI.Texture.EMPTY);

            button2.width = 234;
            button2.height = 42;

            button2.position.set(841, 948);

            button2.cursor = 'pointer'
            button2.interactive = true;
            button2.on('pointerdown', () => {
                this.emit('go-to-next');
            });

            this.addChild(button2);

        })
    }
}
