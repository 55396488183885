import * as PIXI from 'pixi.js';
import { Food } from '../type';

export default class FoodListItem extends PIXI.Container {
    constructor(food: Food, width: number, height: number) {
        super();

        const gfx = new PIXI.Graphics();

        gfx.beginFill(0xdf4510, .01);
        gfx.drawRect(0, 0, width, height);
        gfx.endFill();

        this.width = width;
        this.height = height;

        const text = new PIXI.Text(food.name);

        text.position.set(0, 25);

        this.addChild(gfx);
        // this.addChild(text);
    }
}
