import React from 'react';
import { Link } from 'react-router-dom';
import { getCharacterRoute } from '../routes';

export default function IntroPage() {
    return (
        <>
            <h1>IntroPage</h1>
            <Link to={getCharacterRoute()}>Przejdź Dalej</Link>
            <img src="/placeholders/Wstep.jpg" alt="" />
        </>
    )
}
