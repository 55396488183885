import * as PIXI from 'pixi.js';

// https://github.com/Mwni/PIXI.TextInput
//@ts-ignore
import * as InputText from 'pixi-text-input';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import GameStage from '../game-stage';
import { Howl } from 'howler';

export default class GameAvatarScreen extends GameStage {

    private gender = new BehaviorSubject<'k' | 'm' | null>(null);
    private hair = new BehaviorSubject<'1' | '2' | '3' | '4' | '5' | null>(null);
    private clothes = new BehaviorSubject<'1' | '2' | '3' | '4' | '5' | null>(null);
    private login = new BehaviorSubject<string>('');

    private avatarCode = new BehaviorSubject<string | null>(null);

    constructor(private audio: Howl) {
        super();

        this.loadAssets([
            ['avatar/placeholder', '/placeholders/avatar.png'],
            ['avatar-spritesheet-0', '/assets/avatar/avatar-0.json'],
            ['avatar-spritesheet-1', '/assets/avatar/avatar-1.json']
        ]).then(() => {
            this.initStage();
        });
    }

    private initStage() {

        combineLatest([
            this.gender,
            this.hair,
            this.clothes
        ]).pipe(
            map(([gender, hair, clothes]) => {
                if (gender && hair && clothes) {
                    return `${gender}-${hair}-${clothes}`
                }

                return null;
            })
        ).subscribe(this.avatarCode)


        this.initForm();
        this.initAvatar();
        this.initPopup();
    }

    private initForm() {

        const bg = PIXI.Sprite.from('avatar/bg');
        this.addChild(bg);

        //---------------------------------

        const mButton = PIXI.Sprite.from('avatar/portret-m');
        mButton.interactive = true;
        mButton.cursor = 'pointer';
        mButton.position.set(470, 269);

        mButton.on('pointerdown', () => {
            this.audio.play('on-click');
            this.gender.next('m');
        });

        this.gender.subscribe(g => {
            mButton.alpha = (g === 'm') ? 1 : .5;
        })

        this.addChild(mButton)

        //---------------------------------

        const kButton = PIXI.Sprite.from('avatar/portret-k');
        kButton.interactive = true;
        kButton.cursor = 'pointer';
        kButton.position.set(353, 269);

        kButton.on('pointerdown', () => {
            this.audio.play('on-click');
            this.gender.next('k');
        });

        this.gender.subscribe(g => {
            kButton.alpha = (g === 'k') ? 1 : .5;
        })

        this.addChild(kButton);

        //---------------------------------

        const BUTTON_SIZE = 38;

        const hairOptions: [string, number][] = [
            ['1', 0xe59c3b],
            ['2', 0xd86038],
            ['3', 0x433d36],
            ['4', 0x000000],
            ['5', 0x542505],
        ];

        hairOptions.forEach(([id, color], index) => {
            const button = new PIXI.Graphics();

            button.beginFill(0x000000);
            button.drawRect(-((BUTTON_SIZE / 2) + 2), -((BUTTON_SIZE / 2) + 2), BUTTON_SIZE + 4, BUTTON_SIZE + 4);
            button.beginFill(color);
            button.drawRect(-BUTTON_SIZE / 2, -BUTTON_SIZE / 2, BUTTON_SIZE, BUTTON_SIZE);

            button.interactive = true;
            button.cursor = 'pointer';
            button.on('pointerdown', () => {
                this.audio.play('on-click');
                this.hair.next(id as any);
            });

            this.hair.subscribe(value => {
                button.alpha = (value === id) ? 1 : .5;
            })

            button.position.set(353 + BUTTON_SIZE / 2 + 50 * index, 455);

            this.addChild(button);
        })

        //----------------------------------




        const clothesOptions: [string, number][] = [
            ['1', 0xffffff],
            ['2', 0x8cc63f],
            ['3', 0xde29f3],
            ['4', 0x4cb3f5],
            ['5', 0xd20000],
        ];

        clothesOptions.forEach(([id, color], index) => {
            const button = new PIXI.Graphics();

            button.beginFill(0x000000);
            button.drawRect(-((BUTTON_SIZE / 2) + 2), -((BUTTON_SIZE / 2) + 2), BUTTON_SIZE + 4, BUTTON_SIZE + 4);
            button.beginFill(0xffffff);
            button.drawRect(-BUTTON_SIZE / 2, -BUTTON_SIZE / 2, BUTTON_SIZE, BUTTON_SIZE);
            button.beginFill(color);
            button.drawRect(-BUTTON_SIZE / 2, -BUTTON_SIZE / 2, BUTTON_SIZE, BUTTON_SIZE);

            this.clothes.subscribe(value => {
                button.alpha = (value === id) ? 1 : .5;
            })

            button.interactive = true;
            button.cursor = 'pointer';
            button.on('pointerdown', () => {
                this.audio.play('on-click');
                this.clothes.next(id as any);
            });

            button.position.set(353 + BUTTON_SIZE / 2 + 50 * index, 595);

            this.addChild(button);
        })


        //----------------------------------

        const input = new InputText({
            input: {
                fontSize: '18pt',
                width: '220px',
                color: '#26272E'
            }
        })

        input.x = 374;
        input.y = 720;

        input.on('input', (text: string) => {
            this.login.next(text)
        })

        input.placeholder = 'Podaj swoje imię'
        this.addChild(input)
        input.focus()

        //--------------------------------

        const nextButton = PIXI.Sprite.from('button/go-next');
        nextButton.position.set(241, 859);

        nextButton.interactive = true;
        nextButton.cursor='pointer';



        combineLatest([
            this.login,
            this.avatarCode
        ]).subscribe(([login,code]) => {
            nextButton.visible = (code !== null) && (login !== '');
        })

        nextButton.on('pointerdown', () => {
            this.emit('go-to-next', {
                login: this.login.value,
                avatar: this.avatarCode.value
            });
        });

        this.addChild(nextButton);
    }

    private initAvatar() {
        const avatar = new PIXI.Sprite();

        avatar.anchor.set(.5);

        avatar.position.set(1400, this.height * .5);

        this.avatarCode.subscribe(code => {
            if (code !== null) {
                avatar.texture = PIXI.Texture.from('avatar/' + code);
            }
        })

        this.addChild(avatar);
    }

    private initPopup() {
        const popup = PIXI.Sprite.from('avatar/popup');
        popup.interactive = true;

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        button.interactive = true;
        button.cursor = 'pointer';
        button.position.set(792,591)

        button.width = 336;
        button.height = 64;

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            popup.visible = false;
            button.visible = false;
        })

        this.addChild(popup);
        this.addChild(button);
    }
}
