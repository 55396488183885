import * as PIXI from 'pixi.js';
import { Observable } from 'rxjs';

export default class Stoper extends PIXI.Container {
    private base = PIXI.Sprite.from('tort/stoper/base');
    private glass = PIXI.Sprite.from('tort/stoper/glass');
    private arrow = PIXI.Sprite.from('tort/stoper/arrow');

    constructor(time: Observable<number>) {
        super();

        this.glass.anchor.set(.5);
        this.base.anchor.set(.5, .56);
        this.arrow.anchor.set(.5, .82);

        this.addChild(this.base);
        this.addChild(this.arrow);
        this.addChild(this.glass);

        time.subscribe(value => {
            this.arrow.rotation = 2*Math.PI * (value / 60);
        })
    }
}
