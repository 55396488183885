import { BehaviorSubject } from "rxjs"

export type TextureData = {
    id: string,
    anchor: {
        x: number,
        y: number
    }
}

export enum USER_STATE {
    IDLE = 'IDLE',
    // User przeciąg naczynie z menu na palnik
    MOVING_TOOL = 'MOVING_TOOL',
    MOVING_FOOD = 'MOVING_FOOD',
    MOVING_READY_FOOD = 'MOVING_READY_FOOD',
    MOVING_COMBO = 'MOVING_COMBO',
    USING_SHAKE_TOOL = 'USING_SHAKE_TOOL',
    USING_FLIP_TOOL = 'USING_FLIP_TOOL'
}

export type Tool = {
    id: string,
    name: string,
    texture: TextureData,
    makeRate: number
}

export type Food = {
    id: string,
    name: string,
    cookingType: CookingModelData,
    allowedTools: string[]
}

export type CookingSpotData = {
    id: string,
    x: number,
    y: number,
    toolData: BehaviorSubject<Tool | null>,
    foodData: BehaviorSubject<Food | null>,
    completeData: BehaviorSubject<number[]>,
    fireData: BehaviorSubject<0 | .5 | 1>,
    shakeTime: BehaviorSubject<number>,
    cookingModel: BehaviorSubject<CookingModelData | null>,
    activeSideData: BehaviorSubject<number>,
    size: 'big' | 'small'
}

export type PlateData = {
    food?: Food
    complete?: number[],
    id: string
}

export type FireControllData = {
    id: string,
    x: number,
    y: number,
    fireData: BehaviorSubject<0 | .5 | 1>,
}

export enum COOKING_TYPE {
    BASIC,
    SINGLE_TARGET,
    MULTI_TARGET,
}

export type BasicCookingData = {
    type: COOKING_TYPE.BASIC,
    max: number
    sides: number
}

export type SingleTargetCookingData = {
    type: COOKING_TYPE.SINGLE_TARGET,
    max: number,
    target: number
    sides: number
}

export type DoubleSideTripleTargetCookingData = {
    type: COOKING_TYPE.MULTI_TARGET,
    max: number,
    targets: [number, number, number],
    sides: number
}

export type Combo = {
    food: Food,
    tool: Tool,
    complete: number[],
    side: number,
}

export type CookingModelData = BasicCookingData | SingleTargetCookingData | DoubleSideTripleTargetCookingData


export type Order = {
    id: number,
    foodId: string,
    completeGoal: number
}
