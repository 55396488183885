import * as PIXI from 'pixi.js';

export default class FlipControll extends PIXI.Container {
    constructor() {
        super();

        const gfx = new PIXI.Graphics();

        gfx.beginFill(0xddff22, .01);
        gfx.drawRect(0, 0, 125, 230);
        gfx.endFill()


        this.addChild(gfx);
    }

}
