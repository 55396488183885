import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import KitchenDataStorage from "./data-storage";
import { Tool, Food, CookingModelData, Order, PlateData } from "./type";

export function initCookingSystem(dataStorage: KitchenDataStorage, clock: Observable<any>) {
    const data: [
        BehaviorSubject<Tool | null>,
        BehaviorSubject<Food | null>,
        BehaviorSubject<0 | .5 | 1>,
        BehaviorSubject<number[]>,
        BehaviorSubject<number>,
        BehaviorSubject<CookingModelData | null>,
        BehaviorSubject<number>,
    ][] = [
            [dataStorage.spot1Tool, dataStorage.spot1Food, dataStorage.spot1Fire, dataStorage.spot1Complete, dataStorage.spot1ShakeTime, dataStorage.spot1CookingModel, dataStorage.spot1ActiveSide],
            [dataStorage.spot2Tool, dataStorage.spot2Food, dataStorage.spot2Fire, dataStorage.spot2Complete, dataStorage.spot2ShakeTime, dataStorage.spot2CookingModel, dataStorage.spot2ActiveSide],
            [dataStorage.spot3Tool, dataStorage.spot3Food, dataStorage.spot3Fire, dataStorage.spot3Complete, dataStorage.spot3ShakeTime, dataStorage.spot3CookingModel, dataStorage.spot3ActiveSide],
            [dataStorage.spot4Tool, dataStorage.spot4Food, dataStorage.spot4Fire, dataStorage.spot4Complete, dataStorage.spot4ShakeTime, dataStorage.spot4CookingModel, dataStorage.spot4ActiveSide]
        ]

    data.forEach(([toolData, foodData, fireData, completeData, shakeTimeData, modelData, sideIndex]) => {

        clock.subscribe(() => {
            const tool = toolData.value;
            const food = foodData.value;
            const fire = fireData.value;
            const shakeTime = shakeTimeData.value;
            const model = modelData.value;
            const side = sideIndex.value;

            if (tool && food) {
                if (shakeTime > 0) {
                    shakeTimeData.next(Math.max(0, shakeTime - 1));
                } else {
                    if (model) {
                        let result = [...completeData.value]
                        result[side] = Math.min(result[side] + tool.makeRate * Number(fire), model.max);

                        if (result.length !== model.sides) {
                            result.length = model.sides;
                            for (let i = 0; i < result.length; i++) {
                                result[i] = result[i] ? result[i] : 0
                            }
                        }

                        completeData.next(result);
                    }
                }
            }
        });
    });
}

const ordersOptions = [
    {
        foodId: 'warzywa',
        completeGoal: 50
    },
    {
        foodId: 'zupa',
        completeGoal: 50
    },
    {
        foodId: 'sos',
        completeGoal: 50
    },
    {
        foodId: 'kurczak',
        completeGoal: 50
    },
    {
        foodId: 'stek',
        completeGoal: 17
    },
    {
        foodId: 'stek',
        completeGoal: 50
    },
    {
        foodId: 'stek',
        completeGoal: 83
    }
]

let orderID = 0;

export function initOrderSenderSystem(dataStorage: KitchenDataStorage, clock: Observable<any>) {
    clock.subscribe(() => {
        if (dataStorage.orders.value.length >= 3) {
            return;
        }

        dataStorage.orders.next([
            ...dataStorage.orders.value,
            {
                id: orderID++,
                ...ordersOptions[Math.floor(Math.random() * ordersOptions.length)]
            }
        ])
    })
}

export function initOrderReceiverSystem(dataStorage: KitchenDataStorage, clock: Observable<any>) {

    combineLatest([
        dataStorage.orders,
        dataStorage.plate1Data,
        dataStorage.plate2Data,
        dataStorage.plate3Data,
    ])
        .subscribe(([orders, plate1, plate2, plate3]) => {
            orders.forEach(o => {
                if (plate1 && isPrepered(o, plate1)) {
                    dataStorage.plate1Data.next({ id: dataStorage.plate1Data.value.id });
                    dataStorage.orders.next(orders.filter(order => order.id !== o.id));
                }

                if (plate2 && isPrepered(o, plate2)) {
                    dataStorage.plate2Data.next({ id: dataStorage.plate2Data.value.id });
                    dataStorage.orders.next(orders.filter(order => order.id !== o.id));
                }

                if (plate3 && isPrepered(o, plate3)) {
                    dataStorage.plate3Data.next({ id: dataStorage.plate3Data.value.id });
                    dataStorage.orders.next(orders.filter(order => order.id !== o.id));
                }
            })
        })

    return {
        /**
         * Umieszcza potrawę na talerzu. Zwraca TRUE/FALSE w zależności czy potrawa spełnia jakieś zamówienie.
         */
        putFoodOnPlate: (food: Food, complete: number[], id: string) => {

            const orders = dataStorage.orders.value;

            let correct = false;

            orders.forEach(o => {
                if (isPrepered(o, { food, complete, id })) {
                    correct = true;
                    dataStorage.orders.next(orders.filter(order => order.id !== o.id));
                }
            });

            if (correct) {
                return true;
            } else {
                switch (id) {
                    case 'plate1':
                        dataStorage.plate1Data.next({ id: dataStorage.plate1Data.value.id, food, complete });
                        break;

                    case 'plate2':
                        dataStorage.plate2Data.next({ id: dataStorage.plate2Data.value.id, food, complete });
                        break;

                    case 'plate3':
                        dataStorage.plate3Data.next({ id: dataStorage.plate3Data.value.id, food, complete });
                        break;
                }
            }
        }
    }
}

/**
 * Sprawdza czy danie na talerzu spełnia wymogi zamówienia
 */
function isPrepered(order: Order, { food, complete }: PlateData): boolean {

    if (!food || !complete) {
        return false;
    }

    if (food.id !== order.foodId) {
        return false;
    }

    return complete.every(v => Math.abs(v - order.completeGoal) < 10);
}
