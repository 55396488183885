import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import createGame from "./game";

type Props = {
    stage: string
}

const Container = styled.div`
    padding-top: 56%;
    position: absolute;
    width: 100%;

    &>* {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    canvas {
        max-width: 100%;
        max-height: 100%;
    }
`;

const CookingGame: React.FC<Props> = ({ stage }) => {
    const gameContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if (gameContainer.current) {
            const {
                uninstall
            } = createGame({
                container: gameContainer.current,
                stargingStage: stage
            });

            return () => { uninstall() }
        }
    }, [stage])

    return (
        <Container>
            <div ref={gameContainer}></div>
        </Container >
    )
}

export default CookingGame;
