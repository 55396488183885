export type PieIngredient = {
    id: string,
    name: string,
    sweetness: number,
    texture: number,
    time: number,
    type: 'pie' | 'meringue' | 'fruit'
}

export type TortOrder = {
    id: string,
    pie: string,
    fruit: string,
    meringue: string,
}

export type TortOrderFull = {
    id: string
    pie: PieIngredient,
    fruit: PieIngredient,
    meringue: PieIngredient,
    sweetness: number,
    texture: number
}

export enum USER_STATE {
    SELECTING = 'SELECTING',

    MAKING = 'MAKING',

    COMPLETE = 'COMPLETE'
}
