import React from "react";
import { Link } from "react-router-dom";
import { getAvatarRoute } from "../routes";

import './style.scss';

export default function RulesPage() {
    return <div className="rules-page">
        <div className="container">
            <div className="column">
                <div className="frame-container">
                    <div className="frame-container-2">
                        <div className="frame">
                            <div className="header">
                                <h1>Zasady gry</h1>
                            </div>

                            <div className="content">
                                <div>
                                    <p>Witamy w wirtualnej restauracji, którą
                                    za chwilę otworzycie. Staniecie się kucharzami, a waszym zadaniem będzie
                                jak najlepsze odpowiedzenie na potrzeby klientów. Im lepiej uda się Wam na nie odpowiedzieć tym lepszy wynik otrzymacie.</p>

                                    <p>W tym celu będziecie planować proces produkcji jedzenia i tworzyć łańcuch stanowisk kuchennych. Po zalogowaniu podzielcie się zadaniami.</p>

                                    <p>Każde dobrze zrealizowane zadanie da
                                    Wam punkty, które przełożą się na miejsce
                                w rankingu.</p>

                                    <p>Liczy się szybkość podejmowania decyzji
                                i elastyczność. Gotowi? No to zaczynamy!</p>

                                </div>

                            </div>

                            <div className="footer-spacer"></div>

                            <div className="footer">
                                <Link to={getAvatarRoute()}><span>Przejdź</span> <span>dalej</span></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
}
