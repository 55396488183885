import { Howl } from 'howler';
import * as PIXI from 'pixi.js';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../..';
import UserAvatar from '../../components/user-avatar';
import GameStage from '../game-stage';

export default class RoomScreen extends GameStage {

    private phase = new BehaviorSubject<'popup1' | 'popup2' | 'normal'>('popup1');

    constructor(private userData: BehaviorSubject<UserData | null>, private audio: Howl) {
        super()

        this.sortableChildren = true;

        this.loadAssets([
            ['room/spritesheet', '/assets/room/room.json']
        ])
            .then(() => {
                const sprite = PIXI.Sprite.from('room/full');
                this.addChild(sprite);

                const button1 = new PIXI.Sprite(PIXI.Texture.EMPTY);

                button1.anchor.set(.5);
                button1.position.set(970, 210);

                button1.width = 350;
                button1.height = 170;

                button1.interactive = true;
                button1.cursor = 'pointer';

                button1.on('pointerdown', () => {
                    this.audio.play('avatar-confirm');
                    this.emit('go-to-cooking');
                });

                this.addChild(button1);


                //--------------------------------------

                const button2 = new PIXI.Sprite(PIXI.Texture.EMPTY);

                button2.anchor.set(.5);
                button2.position.set(630, 840);

                button2.width = 330;
                button2.height = 170;

                button2.interactive = true;
                button2.cursor = 'pointer';

                button2.on('pointerdown', () => {
                    this.audio.play('avatar-confirm');
                    this.emit('go-to-tort');
                });

                this.addChild(button2);

                //----------------------------------------

                const avatar = new UserAvatar(this.userData);
                avatar.position.set(1479, 81);

                this.addChild(avatar);

                this.initPopup1();
                this.initPopup2();

                //---------------------------------------

                const endButton = new PIXI.Sprite(PIXI.Texture.EMPTY);

                endButton.position.set(1378, 886);

                endButton.width = 334;
                endButton.height = 61;

                endButton.interactive = true;
                endButton.cursor = 'pointer';

                endButton.on('pointerdown', () => {
                    this.emit('go-to-end');
                });

                this.addChild(endButton);
            })
    }

    private initPopup1() {
        const popup = PIXI.Sprite.from('room/popup1');
        popup.interactive = true;

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        button.interactive = true;
        button.cursor = 'pointer';
        button.position.set(801, 748)

        button.width = 336;
        button.height = 64;

        this.phase.subscribe(phase => {
            popup.visible = phase === 'popup1';
            button.visible = phase === 'popup1';
        })

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            this.phase.next('popup2');
        })

        this.addChild(popup);
        this.addChild(button);
    }

    private initPopup2() {
        const popup = PIXI.Sprite.from('room/popup2');
        popup.interactive = true;

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        button.interactive = true;
        button.cursor = 'pointer';
        button.position.set(801, 661)

        button.width = 336;
        button.height = 64;

        this.phase.subscribe(phase => {
            popup.visible = phase === 'popup2';
            button.visible = phase === 'popup2';
        })

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            this.phase.next('normal');
        })

        this.addChild(popup);
        this.addChild(button);
    }
}
