import * as PIXI from 'pixi.js';
import { combineLatest, Observable } from 'rxjs';

type Config = {
    size: number,
    gap: number,
    value: Observable<number>
    goal: Observable<number>
}

export default class TextureMeter extends PIXI.Container {

    constructor(private config: Config) {
    super();

    const gfx = new PIXI.Graphics();

    this.addChild(gfx);

    combineLatest([
        config.value,
        config.goal
    ]).subscribe(([value, goal]) => {
        gfx.clear();
        for (let i = 0; i < 6; i++) {
            if (i < value) {
                gfx.beginFill(0x433d36, 1);
            } else {
                gfx.beginFill(0x9f9a91, 1);
            }

            gfx.drawRect((config.gap + config.size) * i, 0, config.size, config.size);

            if (i < value) {
                gfx.beginFill(0xffce80);
            } else if (i < goal) {
                gfx.beginFill(0xfce1b4);
            } else {
                gfx.beginFill(0xfcf9ef, 1);
            }

            gfx.drawRect((config.gap + config.size) * i + 2, 2, config.size - 4, config.size - 4);
            gfx.endFill();
        }
    })
}
}
