import * as PIXI from 'pixi.js';

export default class TortIntroPopup extends PIXI.Container {
    constructor() {
        super();

        this.zIndex = 100;
        this.interactive = true;

        this.addChild(PIXI.Sprite.from('tort/popup/intro'));

        const button = new PIXI.Sprite(PIXI.Texture.EMPTY);

        button.anchor.set(.5);

        button.position.set(960, 690);
        button.width = 335;
        button.height = 63;

        button.cursor = 'pointer';
        button.interactive = true;
        button.on('pointerdown', () => {
            this.emit('close');
        });

        this.addChild(button);
    }
}
