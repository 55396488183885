import * as PIXI from 'pixi.js';

export default class CookingIntroPopup extends PIXI.Container {
    constructor() {
        super();

        this.zIndex = 100;
        this.interactive = true;

        this.addChild(PIXI.Sprite.from('cooking/intro-popup'));

        const button = new PIXI.Sprite(PIXI.Texture.EMPTY);

        button.position.set(795, 696);
        button.width = 335;
        button.height = 63;

        button.cursor = 'pointer';
        button.interactive = true;
        button.on('pointerdown', () => {
            this.emit('close');
        });

        this.addChild(button);
    }
}
