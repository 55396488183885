import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import ModulesProviders from './modules/providers';
import * as serviceWorker from './serviceWorker';

if (window.screen.orientation) {
    window.screen.orientation.lock('landscape')
        .catch(e => {});
}


ReactDOM.render(
    <React.StrictMode>
        <ModulesProviders>
            <App />
        </ModulesProviders>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
