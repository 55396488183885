export function getFoodMenuItemLayoutData(id: string) {
    switch (id) {
        case 'warzywa':
            return {
                x: 1455,
                y: 505,
                width: 375,
                height: 100
            }

        case 'kurczak':
            return {
                x: 1455,
                y: 505 + 100 + 4,
                width: 375,
                height: 109
            }

        case 'stek':
            return {
                x: 1455,
                y: 505 + 100 + 4 + 109 + 5,
                width: 375,
                height: 86
            }

        case 'zupa':
            return {
                x: 1455,
                y: 505 + 100 + 4 + 109 + 5 + 86 + 3,
                width: 375,
                height: 95
            }

        case 'sos':
            return {
                x: 1455,
                y: 505 + 100 + 4 + 109 + 5 + 86 + 3 + 95 + 5,
                width: 375,
                height: 90
            }
    }

    return {
        x: 0,
        y: 0,
        width: 100,
        height: 100
    }
}
