import * as PIXI from 'pixi.js';
import { Tool } from '../type';
export default class ToolListItem extends PIXI.Container {
    constructor(tool: Tool, width: number, height: number) {
        super();

        const sprite = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        sprite.width = width;
        sprite.height = height;
        sprite.position.set(0, 25);

        this.addChild(sprite);
    }
}
