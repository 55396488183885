import * as PIXI from 'pixi.js';
import GameStage from '../game-stage';

export default class GameRulesScreen extends GameStage {
    constructor() {
        super();

        this.loadAssets([
            ['rules', '/assets/rules/rules.json']
        ]).then(() => {
            const sprite = PIXI.Sprite.from('rules/full');
            this.addChild(sprite);

            const button = new PIXI.Sprite(PIXI.Texture.EMPTY);

            button.width = 336;
            button.height = 64;

            button.position.set(297,955);

            button.cursor = 'pointer'
            button.interactive = true;
            button.on('pointerdown', () => {
                this.emit('go-to-next');
            });

            this.addChild(button);

        })
    }
}
