import * as PIXI from 'pixi.js'
import { BehaviorSubject } from 'rxjs';
import { GameConfig } from '../cooking-game/game';
import GameAvatarScreen from './stages/avatar';
import Kitchen from './stages/cooking';
import GameIntroScreen from './stages/game-intro';
import GameStage from './stages/game-stage';
import GameGoalsScreen from './stages/goals';
import GameHomeScreen from './stages/home';
import RoomScreen from './stages/room';
import GameRulesScreen from './stages/rules';
import Tort from './stages/tort';
import FontFaceObserver from 'fontfaceobserver';
import { Howl } from 'howler';
import Axios from 'axios';
import GameEndScreen from './stages/end';

export type UserData = { login: string, avatar: string }

export default class Cooking extends PIXI.Application {

    private stages: GameStage[] = []

    // private userData: {login: string, avatar: string} | null = null;
    private userData = new BehaviorSubject<UserData | null>({
        login: 'xxxaesc',
        avatar: 'm-3-2'
    })

    private _audio?: Howl
    private get audio(): Promise<Howl> {
        if (this._audio) {
            return Promise.resolve(this._audio);
        }

        return Axios.get('./assets/audio/audio.json').then(
            response => {
                this._audio = new Howl(response.data);

                // [
                //     'load', 'loaderror', 'playerror',
                //     'play', 'end', 'pause', 'stop',
                //     'mute', 'volume', 'rate',
                //     'seek', 'fade', 'unlock']
                //     .forEach((event) => {
                //         this._audio?.on(event, (id: number) => {
                //             console.log('AUDIO', id, event)
                //         })
                //     })

                return this._audio;
            }
        )
    }

    constructor(width: number, height: number, backgroundColor: number, private config: GameConfig) {
        super({
            width,
            height,
            antialias: true,
            autoDensity: true,
            transparent: true
        });

        this.renderer.plugins.interaction.autoPreventDefault = false;
        this.renderer.view.style.touchAction = 'auto';

        Promise.all([
            this.loadAssets([
                ['buttons/spritesheet', '/assets/buttons/buttons.json'],
                ['portrets/spritesheet', '/assets/portrets/portrets.json'],
                ['shared/spritesheet', '/assets/shared/shared.json']
            ]),
            ...['bodonino2comdotult'].map(name => {
                const font = new FontFaceObserver(name);
                return font.load();
            })

        ])
            .then(() => {
                this.initGame();
            })


    }

    private initGame() {

        this.stage.addChild(PIXI.Sprite.from('shared/background'))

        this.initAudio();

        this.showHomeScreen();
        // this.showAvatarScreen();
        // this.showIntroScreen();
        // this.showRoomScreen();
        // this.showKitchenScreen();
        // this.showTortScreen();
        // this.showEndScreen();

    }

    private screens: GameStage[] = [];
    private introScreen?: GameIntroScreen;
    private homeScreen?: GameHomeScreen;
    private goalsScreen?: GameGoalsScreen;
    private rulesScreen?: GameRulesScreen;
    private avatarScreen?: GameAvatarScreen;
    private endScreen?: GameEndScreen;

    private showHomeScreen() {
        this.screens.forEach(s => { s.visible = false });

        if (!this.homeScreen) {
            this.homeScreen = new GameHomeScreen();
            this.screens.push(this.homeScreen);
            this.stage.addChild(this.homeScreen);

            this.homeScreen.on('go-to-next', () => {
                this.audio.then(a => a.play('on-click'));
                this.showRulesScreen()
            });
        }
    }

    private showRulesScreen() {
        this.screens.forEach(s => { s.visible = false });

        if (!this.goalsScreen) {
            this.goalsScreen = new GameGoalsScreen();
            this.screens.push(this.goalsScreen);
            this.stage.addChild(this.goalsScreen);

            this.goalsScreen.on('go-to-next', () => {
                this.audio.then(a => a.play('on-click'));
                this.showGoalsScreen()
            });
        }
    }

    private showGoalsScreen() {
        this.screens.forEach(s => { s.visible = false });

        if (!this.rulesScreen) {
            this.rulesScreen = new GameRulesScreen();
            this.screens.push(this.rulesScreen);
            this.stage.addChild(this.rulesScreen);

            this.rulesScreen.on('go-to-next', () => {
                this.audio.then(a => a.play('on-click'));
                this.showAvatarScreen()
            });
        }
    }

    private showAvatarScreen() {
        this.screens.forEach(s => { s.visible = false });

        this.audio.then(audio => {

            //@ts-ignore
            window.xxxx = audio;

            if (!this.avatarScreen) {
                this.avatarScreen = new GameAvatarScreen(audio);
                this.screens.push(this.avatarScreen);
                this.stage.addChild(this.avatarScreen);

                this.avatarScreen.on('go-to-next', ({ login, avatar }: { login: string, avatar: string }) => {
                    this.userData.next({ login, avatar });
                    this.audio.then(a => a.play('avatar-confirm'));
                    this.showIntroScreen()
                });
            }
        })

    }

    private showIntroScreen() {

        if (this.userData.value === null) {
            this.showAvatarScreen();
            return;
        }

        this.screens.forEach(s => { s.visible = false });

        this.audio.then(audio => {


            if (!this.introScreen) {
                this.introScreen = new GameIntroScreen(this.userData, audio);
                this.screens.push(this.introScreen);
                this.stage.addChild(this.introScreen);

                this.introScreen.on('go-to-next', () => {
                    this.showRoomScreen()
                    this.audio.then(a => a.play('on-click'));
                });
            }
        })
    }

    private roomScreen?: RoomScreen;

    private showRoomScreen() {

        this.screens.forEach(s => { s.visible = false });

        this.audio.then(audio => {
            if (!this.roomScreen) {
                this.roomScreen = new RoomScreen(this.userData, audio);
                this.screens.push(this.roomScreen);
                this.stage.addChild(this.roomScreen);

                this.roomScreen.on('go-to-tort', () => {
                    audio.play('on-click');
                    this.showTortScreen()
                });

                this.roomScreen.on('go-to-cooking', () => {
                    audio.play('on-click');
                    this.showKitchenScreen()
                });

                this.roomScreen.on('go-to-end', () => {
                    audio.play('on-click');
                    this.showEndScreen();
                });
            } else {
                this.roomScreen.visible = true;
            }
        })
    }

    private tortScreen?: Tort;

    private showTortScreen() {

        this.screens.forEach(s => { s.visible = false });
        this.audio.then(audio => {

            if (!this.tortScreen) {
                this.tortScreen = new Tort(this.userData, audio);
                this.screens.push(this.tortScreen);
                this.stage.addChild(this.tortScreen)

                this.tortScreen.on('go-back', () => {
                    this.showRoomScreen();
                })
            } else {
                this.tortScreen.visible = true;
            }
        })


    }

    private kitcheScreen?: Kitchen;

    private showKitchenScreen() {

        this.screens.forEach(s => { s.visible = false });

        this.audio.then(audio => {
            if (!this.kitcheScreen) {
                this.kitcheScreen = new Kitchen(this.userData, audio);
                this.screens.push(this.kitcheScreen);
                this.stage.addChild(this.kitcheScreen)

                this.kitcheScreen.on('go-back', () => {
                    this.showRoomScreen();
                })
            } else {
                this.kitcheScreen.visible = true;
            }
        })

    }

    private showEndScreen() {
        this.screens.forEach(s => { s.visible = false });

        this.audio.then(audio => {

            if (!this.endScreen) {
                this.endScreen = new GameEndScreen();
                this.screens.push(this.endScreen);
                this.stage.addChild(this.endScreen);
            } else {
                this.endScreen.visible = true;
            }

            this.endScreen.on('go-to-next', () => {
                audio.play('on-click');
                //@ts-ignore
                window.location = 'https://endorfina.eu/#slide6';
            })
        })

    }

    public getSceenBlob() {
        const data = this.renderer.extract.canvas(this.stage).toDataURL('image/png', 1);
        return data;
    }

    public destroy() {

        this.stages.forEach(stage => {
            stage.destroy();
        })

        super.destroy();
    }

    private loadAssets(assets: any[]) {
        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    private initAudio() {

        this.stage.interactive = true;
        this.stage.on('pointerdown', () => {

        })

    }
}
