export function getHomeRoute() {
    return '/';
}

export function getIntroRoute() {
    return '/intro';
}

export function getCharacterRoute() {
    return '/character';
}

export function getGoalsRoute() {
    return '/goals';
}

export function getGameRoute() {
    return '/game';
}

export function getRulesRoute() {
    return '/rules';
}

export function getAvatarRoute() {
    return '/avatar';
}
