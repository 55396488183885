import * as PIXI from 'pixi.js';
import { Tool } from '../type';
import ToolListItem from './tool-list-item';

export default class ToolList extends PIXI.Container {
    constructor(private toolsData: Tool[], private callbacks: {
        onToolItemDragStart: (tool: Tool) => void
    }) {
        super();

        this.toolsData.forEach((data) => {

            const { x, y, width, height } = (() => {
                switch (data.id) {
                    case 'smallPot':
                        return {
                            x: 95,
                            y: 505 - 270,
                            width: 375,
                            height: 100
                        }

                    case 'bigPot':
                        return {
                            x: 95,
                            y: 505 + 100 + 4 - 270,
                            width: 375,
                            height: 132
                        }

                    case 'pan':
                        return {
                            x: 95,
                            y: 505 + 100 + 4 + 109 + 5 + 23 - 270,
                            width: 375,
                            height: 90
                        }

                    case 'wok':
                        return {
                            x: 95,
                            y: 505 + 100 + 4 + 109 + 5 + 23 + 95 - 270,
                            width: 375,
                            height: 92
                        }
                }

                return {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100
                }
            })();

            const toolListItem = new ToolListItem(data, width, height);

            toolListItem.interactive = true;
            toolListItem.position.set(x, y);
            toolListItem.on('pointerdown', () => this.callbacks.onToolItemDragStart(data))

            this.addChild(toolListItem);

        });
    }
}
