import { Howl } from 'howler';
import * as PIXI from 'pixi.js';
import { BehaviorSubject, from } from 'rxjs';
import { UserData } from '../..';
import UserAvatar from '../../components/user-avatar';
import GameStage from '../game-stage';

export default class GameIntroScreen extends GameStage {
    constructor(private userData: BehaviorSubject<UserData | null>, private audio: Howl) {
        super()

        this.loadAssets([
            ['intro-spritesheet', '/assets/intro/intro.json']
        ])
            .then(() => {
                const sprite = PIXI.Sprite.from('intro/full');
                this.addChild(sprite);

                //------------------------------------------

                const button = new PIXI.Sprite(PIXI.Texture.EMPTY);

                button.anchor.set(.5);
                button.position.set(1220, 1000);

                button.width = 350;
                button.height = 80;

                button.interactive = true;
                button.cursor = 'pointer';

                button.on('pointerdown', () => {
                    this.emit('go-to-next');
                });

                this.addChild(button);

                //------------------------------------------

                const avatar = new UserAvatar(this.userData);
                avatar.position.set(859, 620);

                this.addChild(avatar);


                [
                    {
                        login: 'robert',
                        avatar: 'm-1-1',
                        x: 1,
                        y: 0
                    }, {
                        login: 'anna',
                        avatar: 'k-4-2',
                        x: 2,
                        y: 0
                    }, {
                        login: 'patryk',
                        avatar: 'm-2-4',
                        x: 3,
                        y: 0
                    }, {
                        login: 'michał',
                        avatar: 'm-3-5',
                        x: 4,
                        y: 0
                    },
                    {
                        login: 'ola',
                        avatar: 'k-5-5',
                        x: 0,
                        y: 1
                    }, {
                        login: 'ryszard',
                        avatar: 'm-3-3',
                        x: 1,
                        y: 1
                    }, {
                        login: 'patrycja',
                        avatar: 'k-4-4',
                        x: 2,
                        y: 1
                    }, {
                        login: 'krzysztof',
                        avatar: 'm-2-1',
                        x: 3,
                        y: 1
                    }
                ].forEach(({ login, avatar, x, y }) => {
                    const a = new UserAvatar(from([{ login, avatar }]));
                    a.position.set(859 + 140 * x, 620 + 174 * y);

                    this.addChild(a);
                })

                this.initPopup();

            })
    }

    private initPopup() {
        const popup = PIXI.Sprite.from('intro/popup');
        popup.interactive = true;

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        button.interactive = true;
        button.cursor = 'pointer';
        button.position.set(793,705)

        button.width = 336;
        button.height = 64;

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            popup.visible = false;
            button.visible = false;
        })

        this.addChild(popup);
        this.addChild(button);
    }
}
