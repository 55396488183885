import * as PIXI from 'pixi.js';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PlateData } from '../type';

export default class Plate extends PIXI.Container {

    constructor(
        private data: BehaviorSubject<PlateData>,
        private showContent: Observable<boolean>
    ) {
        super();

        const sprite = new PIXI.Sprite();

        combineLatest([
            this.data,
            this.showContent
        ]).subscribe(([value, showContent]) => {

            if (!showContent) {
                sprite.texture = PIXI.Texture.from('cooking/food/ready/empty');
                return;
            }

            switch (value?.food?.id) {

                case 'zupa':
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/zupa');
                    break;

                case 'kurczak':
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/kurczak');
                    break;

                case 'sos':
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/sos');
                    break;

                case 'stek':
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/stek');
                    break;

                case 'warzywa':
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/warzywa');
                    break;

                default:
                    sprite.texture = PIXI.Texture.from('cooking/food/ready/empty');
            }
        })

        this.addChild(sprite);
    }
}
