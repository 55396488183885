import { BehaviorSubject } from "rxjs";
import { USER_STATE, Tool, Food, Combo, CookingModelData, CookingSpotData, COOKING_TYPE, FireControllData, Order, PlateData } from "./type";

export default class KitchenDataStorage {
    public userState = new BehaviorSubject<USER_STATE>(USER_STATE.IDLE);
    public movingTool = new BehaviorSubject<Tool | null>(null);

    public movingFood = new BehaviorSubject<Food | null>(null);

    public movingCombo = new BehaviorSubject<Combo | null>(null);
    public movingComboSource = new BehaviorSubject<string | null>(null);
    public movingToolSource = new BehaviorSubject<string | null>(null);
    public movingReadyFoodSource = new BehaviorSubject<string | null>(null);

    public spot1Tool = new BehaviorSubject<Tool | null>(null);
    public spot1Food = new BehaviorSubject<Food | null>(null);
    public spot1Fire = new BehaviorSubject<0 | .5 | 1>(0);
    public spot1Complete = new BehaviorSubject<number[]>([0]);
    public spot1ShakeTime = new BehaviorSubject<number>(0);
    public spot1CookingModel = new BehaviorSubject<CookingModelData | null>(null);
    public spot1ActiveSide = new BehaviorSubject<number>(0);

    public spot2Tool = new BehaviorSubject<Tool | null>(null);
    public spot2Food = new BehaviorSubject<Food | null>(null);
    public spot2Fire = new BehaviorSubject<0 | .5 | 1>(0);
    public spot2Complete = new BehaviorSubject<number[]>([0]);
    public spot2ShakeTime = new BehaviorSubject<number>(0);
    public spot2CookingModel = new BehaviorSubject<CookingModelData | null>(null);
    public spot2ActiveSide = new BehaviorSubject<number>(0);

    public spot3Tool = new BehaviorSubject<Tool | null>(null);
    public spot3Food = new BehaviorSubject<Food | null>(null);
    public spot3Fire = new BehaviorSubject<0 | .5 | 1>(0);
    public spot3Complete = new BehaviorSubject<number[]>([0]);
    public spot3ShakeTime = new BehaviorSubject<number>(0);
    public spot3CookingModel = new BehaviorSubject<CookingModelData | null>(null);
    public spot3ActiveSide = new BehaviorSubject<number>(0);

    public spot4Tool = new BehaviorSubject<Tool | null>(null);
    public spot4Food = new BehaviorSubject<Food | null>(null);
    public spot4Fire = new BehaviorSubject<0 | .5 | 1>(0);
    public spot4Complete = new BehaviorSubject<number[]>([0]);
    public spot4ShakeTime = new BehaviorSubject<number>(0);
    public spot4CookingModel = new BehaviorSubject<CookingModelData | null>(null);
    public spot4ActiveSide = new BehaviorSubject<number>(0);


    public plate1Data = new BehaviorSubject<PlateData>({id: 'plate1'});
    public plate2Data = new BehaviorSubject<PlateData>({id: 'plate2'});
    public plate3Data = new BehaviorSubject<PlateData>({id: 'plate3'});

    public orders = new BehaviorSubject<Order[]>([]);

    public cookingSpotsData: CookingSpotData[] = [
        {
            id: 'spot1',
            x: 725 + 112,
            y: 258 + 112,
            toolData: this.spot1Tool,
            foodData: this.spot1Food,
            completeData: this.spot1Complete,
            fireData: this.spot1Fire,
            shakeTime: this.spot1ShakeTime,
            cookingModel: this.spot1CookingModel,
            activeSideData: this.spot1ActiveSide,
            size: 'big'
        },
        {
            id: 'spot2',
            x: 965 + 112,
            y: 258 + 112,
            toolData: this.spot2Tool,
            foodData: this.spot2Food,
            completeData: this.spot2Complete,
            fireData: this.spot2Fire,
            shakeTime: this.spot2ShakeTime,
            cookingModel: this.spot2CookingModel,
            activeSideData: this.spot2ActiveSide,
            size: 'big'
        },
        {
            id: 'spot3',
            x: 725 + 112,
            y: 510 + 112,
            toolData: this.spot3Tool,
            foodData: this.spot3Food,
            completeData: this.spot3Complete,
            fireData: this.spot3Fire,
            shakeTime: this.spot3ShakeTime,
            cookingModel: this.spot3CookingModel,
            activeSideData: this.spot3ActiveSide,
            size: 'big'
        },
        {
            id: 'spot4',
            x: 967 + 112,
            y: 510 + 112,
            toolData: this.spot4Tool,
            foodData: this.spot4Food,
            completeData: this.spot4Complete,
            fireData: this.spot4Fire,
            shakeTime: this.spot4ShakeTime,
            cookingModel: this.spot4CookingModel,
            activeSideData: this.spot4ActiveSide,
            size: 'small'
        }
    ];

    public fireControllData: FireControllData[] = [
        {
            id: 'fire1',
            x: 820,
            y: 795,
            fireData: this.spot1Fire
        },
        {
            id: 'fire2',
            x: 960,
            y: 795,
            fireData: this.spot2Fire
        },
        {
            id: 'fire3',
            x: 820,
            y: 900,
            fireData: this.spot3Fire
        },
        {
            id: 'fire4',
            x: 960,
            y: 900,
            fireData: this.spot4Fire
        }
    ]

    public toolsData: Tool[] = [{
        id: 'smallPot',
        name: 'Mały garnek',
        texture: {
            id: 'small-pot-empty',
            anchor: {
                x: .5,
                y: .5
            }
        },
        makeRate: 1
    }, {
        id: 'bigPot',
        name: 'Duży garnek',
        texture: {
            id: 'big-pot-empty',
            anchor: {
                x: .5,
                y: .5
            }
        },
        makeRate: 2
    }, {
        id: 'pan',
        name: 'Patelnia',
        texture: {
            id: 'pan-empty',
            anchor: {
                x: .675,
                y: .39
            }
        },
        makeRate: 4
    }, {
        id: 'wok',
        name: 'Wok',
        texture: {
            id: 'wok-empty',
            anchor: {
                x: .715,
                y: .35
            }
        },
        makeRate: 3
    }];

    public foodData: Food[] = [
        {
            id: 'warzywa',
            name: 'Warzywa',
            cookingType: {
                type: COOKING_TYPE.SINGLE_TARGET,
                max: 100,
                target: 50,
                sides: 1
            },
            allowedTools: ['wok', 'pan']
        },
        {
            id: 'kurczak',
            name: 'Kurczak',
            cookingType: {
                type: COOKING_TYPE.SINGLE_TARGET,
                max: 100,
                target: 50,
                sides: 1
            },
            allowedTools: ['wok', 'pan']
        },
        {
            id: 'stek',
            name: 'Stek',
            cookingType: {
                type: COOKING_TYPE.MULTI_TARGET,
                max: 100,
                targets: [17, 50, 83],
                sides: 2
            },
            allowedTools: ['wok', 'pan']
        },
        {
            id: 'zupa',
            name: 'Zupa',
            cookingType: {
                type: COOKING_TYPE.SINGLE_TARGET,
                max: 100,
                target: 50,
                sides: 1
            },
            allowedTools: ['bigPot', 'smallPot']
        },
        {
            id: 'sos',
            name: 'Sos',
            cookingType: {
                type: COOKING_TYPE.SINGLE_TARGET,
                max: 100,
                target: 50,
                sides: 1
            },
            allowedTools: ['bigPot', 'smallPot']
        }
    ]
}
