import { Howl } from 'howler';
import * as PIXI from 'pixi.js';
import { map } from 'rxjs/internal/operators/map';
import TortDataStorage from "../data-storage";
import { TortOrderFull } from "../types";
import OrderObject from "./order";

export default class OrderList extends PIXI.Container {

    private orders: { order: TortOrderFull, object: OrderObject }[] = [];

    private orderLayout = [
        [0, 0], [240, 0], [480, 0]
    ];

    constructor(private data: TortDataStorage, private audio: Howl) {
        super()

        this.data.fullOrders.subscribe((orders) => {
            this.orders
                .filter(o => !orders.find(o2 => o2.id === o.order.id))
                .forEach(o => {
                    o.object.destroy();

                    this.orders.splice(this.orders.indexOf(o), 1);
                });

            orders.forEach((order) => {
                const existing = this.orders.find(o => o.order.id === order.id);

                if (!existing) {
                    const newOrder = new OrderObject(order, this.data.selectedOrderId.pipe(map(v => v === order.id)));

                    newOrder.on('pointerdown', () => {
                        this.audio.play('choose-order');
                        this.data.selectOrder(order.id);
                    })

                    this.addChild(newOrder);
                    this.orders.push({ order, object: newOrder });
                }
            });

            this.orders.forEach((order, index) => {
                order.object.position.set(...this.orderLayout[index]);
            })
        })
    }
}
