import * as PIXI from 'pixi.js';
import { Observable } from 'rxjs';
import { UserData } from '..';

export default class UserAvatar extends PIXI.Container {

    constructor(userData: Observable<UserData | null>) {
        super();

        const frame = PIXI.Sprite.from('shared/avatar-frame');

        this.addChild(frame);

        const portret = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        portret.width = 120;
        portret.height = 120;
        portret.position.set(6,6);

        this.addChild(portret);


        const style = new PIXI.TextStyle({
            align: 'center',
            fontFamily: 'bodonino2comdotult',
            fontSize: 30,
            lineHeight: 45,
            fontWeight: 'bold',
            fill: '#433c36',
            padding: 10
        });

        const name = new PIXI.Text('', style);

        this.addChild(name);
        name.position.set(6 + 60 - name.width/2, 146);

        userData.subscribe(data => {
            if (data === null) {
                portret.texture = PIXI.Texture.EMPTY;
                name.text = ''
            } else {
                portret.texture = PIXI.Texture.from(`portret/p-${data.avatar}`);
                name.text = data.login
            }

            name.position.set(6 + 60 - name.width/2, 146);
        })

    }
}
