import { Howl } from 'howler';
import * as PIXI from 'pixi.js';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserData } from '../..';
import UserAvatar from '../../components/user-avatar';
import GameStage from "../game-stage";
import TortDataStorage from "./data-storage";
import TortIntroPopup from './game-objects/intro-popup';
import OrderList from './game-objects/order-list';
import Stoper from './game-objects/stoper';
import SweetnessMeter from './game-objects/sweetness-meter';
import TextureMeter from './game-objects/texture-meter';
import TortTooltip from './game-objects/tooltip';
import TortObject from './game-objects/tort';
import { USER_STATE } from './types';

export default class Tort extends GameStage {

    private tooltip?: TortTooltip;

    private data = new TortDataStorage();

    private phase = new BehaviorSubject<'intro' | 'tutorial' | 'game'>('intro');

    constructor(private userData: BehaviorSubject<UserData | null>, private audio: Howl) {
        super();

        this.sortableChildren = true;

        this.loadAssets([
            ['tort-spritesheet-0', '/assets/tort/tort-0.json'],
            ['tort-spritesheet-1', '/assets/tort/tort-1.json']
        ]).then(() => {

            this.initIntro();

            this.initBackground();

            this.initDebug();
            this.initOrderList();

            this.initIngredients();

            this.initWorkbench();

            this.initSelectedOrder();
            this.initStoper();

            this.initMakeButton();
            this.initResetButton();

            this.initAvatar();

            this.initTooltip();

            this.initButtons();
            this.initTutorial();

            this.initSuccessPopup();

            this.initAudio();
        })
    }

    private initIntro() {
        const introPopup = new TortIntroPopup();
        this.addChild(introPopup);

        introPopup.on('close', () => {
            this.audio.play('on-click');
            this.phase.next('tutorial');
        })

        this.phase.subscribe(state => {
            introPopup.visible = state === 'intro';
        });
    }

    private initBackground() {
        const bg = PIXI.Sprite.from('tort/full');

        this.addChild(bg);
    }

    private initDebug() {
        const text = new PIXI.Text('');

        this.data.userState.subscribe(value => {
            text.text = 'USER_STATE: ' + value;
        })

        // this.addChild(text);
    }

    private initOrderList() {
        const list = new OrderList(this.data, this.audio);

        list.position.set(673, 824);

        this.phase.subscribe((phase) => {
            list.visible = phase === 'game';
        })

        this.addChild(list);
    }

    private initIngredients() {
        [
            { id: 'c1', spriteId: 'tort/ciasto-1', x: 1560, y: 370, sound: 'on-click' },
            { id: 'c2', spriteId: 'tort/ciasto-2', x: 1640, y: 370, sound: 'on-click' },
            { id: 'c3', spriteId: 'tort/ciasto-3', x: 1720, y: 370, sound: 'on-click' },
            { id: 'c4', spriteId: 'tort/ciasto-4', x: 1800, y: 370, sound: 'on-click' },
            { id: 'truskawka', spriteId: 'tort/truskawka', x: 1560, y: 490, sound: 'on-click' },
            { id: 'malina', spriteId: 'tort/malina', x: 1640, y: 490, sound: 'on-click' },
            { id: 'wisnia', spriteId: 'tort/wisnia', x: 1720, y: 490, sound: 'on-click' },
            { id: 'sliwka', spriteId: 'tort/sliwka', x: 1800, y: 490, sound: 'on-click' },
            { id: 'm-rozowa', spriteId: 'tort/rozowy', x: 1560, y: 620, sound: 'meringue' },
            { id: 'm-fioletowa', spriteId: 'tort/fioletowy', x: 1680, y: 620, sound: 'meringue' },
            { id: 'm-pomaranczowa', spriteId: 'tort/pomaranczowy', x: 1800, y: 620, sound: 'meringue' }
        ].forEach(({ id, spriteId, x, y, sound }) => {
            const sprite = PIXI.Sprite.from(spriteId);

            sprite.position.set(x, y);
            sprite.anchor.set(.5);

            sprite.interactive = true;
            sprite.cursor = 'pointer';

            sprite.on('pointerdown', () => {
                this.audio.play(sound);
                this.data.setIngredient(id);
            })

            const ingr = this.data.ingredients.find((i) => i.id === id);

            sprite.on('pointerover', () => {
                if (this.tooltip && ingr) {
                    this.tooltip.setIngredient(ingr, spriteId);
                    this.tooltip.visible = true;
                }
            });

            sprite.on('pointerout', () => {
                if (this.tooltip) {
                    this.tooltip.visible = false;
                }
            })

            this.addChild(sprite);
        })
    }

    private initWorkbench() {
        const tort = new TortObject(this.data.selectedTort);

        tort.position.set(960, 440);

        this.addChild(tort);
    }

    private initSelectedOrder() {
        const selectedOrderIcon = new TortObject(this.data.selectedOrder);

        selectedOrderIcon.position.set(300, 440);
        selectedOrderIcon.scale.set(.6);

        this.addChild(selectedOrderIcon);

        const sweetness = new SweetnessMeter({
            value: this.data.selectedTort.pipe(map(t => t.sweetness)),
            goal: this.data.selectedOrder.pipe(map(t => t ? t.sweetness : 0)),
            size: 25,
            gap: 5
        })

        sweetness.position.set(300, 575);

        this.addChild(sweetness);

        const texture = new TextureMeter({
            value: this.data.selectedTort.pipe(map(t => t.texture)),
            goal: this.data.selectedOrder.pipe(map(t => t ? t.texture : 0)),
            size: 25,
            gap: 5
        })

        texture.position.set(300, 647);

        this.addChild(texture);

    }

    private initStoper() {
        const stoper = new Stoper(this.data.timeToEnd);

        stoper.position.set(962, 155);

        this.addChild(stoper);
    }

    private initMakeButton() {
        const make = PIXI.Sprite.from('button/make-deser');

        make.position.set(968, 704);

        make.on('pointerdown', () => {
            this.audio.play('on-click');
            this.data.startMaking();
        });

        combineLatest([
            this.data.isReadyToMake,
            this.data.userState
        ]).subscribe(([ready, state]) => {
            const active = Boolean(ready) && state === USER_STATE.SELECTING
            if (active) {
                make.interactive = true;
                make.cursor = 'pointer';
                make.alpha = 1;
            } else {
                make.interactive = false;
                make.cursor = 'auto';
                make.alpha = .5;
            }

        });

        this.addChild(make);
    }

    private initResetButton() {
        const make = PIXI.Sprite.from('button/new-deser');

        make.position.set(605, 704);

        make.on('pointerdown', () => {
            this.audio.play('on-click');
            this.data.reset();
        })

        combineLatest([
            this.data.userState
        ]).subscribe(([state]) => {
            if (state === USER_STATE.COMPLETE) {
                make.interactive = true;
                make.cursor = 'pointer';
                make.alpha = 1;
            } else {
                make.interactive = false;
                make.cursor = 'auto';
                make.alpha = .5;
            }

        });

        this.addChild(make);

    }

    private initTooltip() {
        this.tooltip = new TortTooltip();

        this.tooltip.position.set(1115, 300);
        this.tooltip.visible = false;

        this.addChild(this.tooltip);
    }

    private initButtons() {
        const back = PIXI.Sprite.from('button/back');

        back.interactive = true;
        back.cursor = 'pointer';
        back.on('pointerdown', () => {
            this.audio.play('on-click');
            this.emit('go-back');
        });

        back.position.set(19, 978);

        this.addChild(back);
    }

    private initTutorial() {
        const tutorialLenght = 6;
        const buttonsData = [
            {
                step: 1,
                x: 793,
                y: 620,
                w: 336,
                h: 64
            },
            {
                step: 2,
                x: 599,
                y: 620,
                w: 336,
                h: 64
            },
            {
                step: 3,
                x: 668,
                y: 573,
                w: 336,
                h: 64
            },
            {
                step: 4,
                x: 917,
                y: 684,
                w: 336,
                h: 64
            },
            {
                step: 5,
                x: 793,
                y: 678,
                w: 336,
                h: 64
            },
            {
                step: 6,
                x: 793,
                y: 596,
                w: 336,
                h: 64
            },
        ]

        let step = new BehaviorSubject<number>(1);

        const tutSprite = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        tutSprite.interactive = true;
        this.addChild(tutSprite);

        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);
        button.interactive = true;
        button.cursor = 'pointer';

        this.addChild(button);

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            if (step.value >= tutorialLenght) {
                tutSprite.visible = false;
                this.phase.next('game');
            } else {
                step.next(step.value + 1);
            }

        })

        this.phase.subscribe((state) => {
            tutSprite.visible = state === 'tutorial';
            button.visible = state === 'tutorial';
        })

        step.subscribe((step) => {
            tutSprite.texture = PIXI.Texture.from(`tort/tutorial/${step}`);

            const buttonData = buttonsData.find(b => b.step === step);

            if (buttonData) {
                button.position.set(buttonData.x, buttonData.y);
                button.width = buttonData.w;
                button.height = buttonData.h;
            }
        })
    }

    private initSuccessPopup() {
        const popup = PIXI.Sprite.from('tort/popup/success');
        const button = PIXI.Sprite.from(PIXI.Texture.EMPTY);

        button.interactive = true;
        button.cursor = 'pointer';
        button.position.set(793, 449);
        button.width = 336;
        button.height = 64;

        button.on('pointerdown', () => {
            this.audio.play('on-click');
            this.data.serve();
        })

        this.data.isReadyToServe.subscribe(value => {
            popup.visible = value || false;
            button.visible = value || false;
        })

        this.addChild(popup);
        this.addChild(button);

    }

    private initAvatar() {
        const avatar = new UserAvatar(this.userData);

        avatar.position.set(129, 795);
        this.addChild(avatar);
    }

    private initAudio() {
        let timerAudio: number;

        this.data.userState
            .pipe(
                distinctUntilChanged()
            )
            .subscribe((state) => {
                if (state === USER_STATE.MAKING) {
                    this.audio.stop(timerAudio);
                    timerAudio = this.audio.play('timer')
                    this.audio.loop(true, timerAudio)
                } else {
                    if (timerAudio && this.audio.playing(timerAudio)) {
                        this.audio.stop(timerAudio);
                    }
                }

                if (state === USER_STATE.COMPLETE) {
                    this.audio.play('timer-end');
                }
            });
    }
}
