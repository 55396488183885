import * as PIXI from 'pixi.js';
import { combineLatest, Observable } from 'rxjs';
import { CookingModelData, COOKING_TYPE, BasicCookingData, SingleTargetCookingData, DoubleSideTripleTargetCookingData } from '../type';

export class CookingIndicator extends PIXI.Container {

    private bg = new PIXI.Sprite();
    private marks = new PIXI.Graphics();
    private gfxMask = new PIXI.Sprite();

    private text = new PIXI.Text('');
    constructor(
        complete: Observable<number[]>,
        cookingModel: Observable<CookingModelData | null>
    ) {
        super();

        this.bg.anchor.set(.5, 0);
        this.gfxMask.anchor.set(.5, 0);

        this.addChild(this.bg);
        this.addChild(this.marks);
        this.addChild(this.gfxMask);

        combineLatest([
            complete,
            cookingModel
        ]).subscribe(([complete, model]) => {
            switch (model?.type) {
                case COOKING_TYPE.BASIC:
                    this.updateBasicIndicator(complete[0], model);
                    break;

                case COOKING_TYPE.SINGLE_TARGET:
                    this.updateSingleTargetIndicator(complete[0], model);
                    break;

                case COOKING_TYPE.MULTI_TARGET:
                    this.updateTripleTargetIndicator(complete, model);
                    break;
            }
        });

        this.addChild(this.text);
    }

    private updateBasicIndicator(complete: number, { max }: BasicCookingData) {
        this.text.text = `${complete}/${max}`;
    }

    private updateSingleTargetIndicator(complete: number, { max, target }: SingleTargetCookingData) {
        this.bg.texture = PIXI.Texture.from('progress-small');
        this.gfxMask.texture = PIXI.Texture.from('progress-small-mask');
        this.marks.mask = this.gfxMask;


        this.marks.clear();
        this.marks.beginFill(0xffffff);
        this.marks.drawCircle(target / max * this.bg.width - this.bg.width / 2, this.bg.height / 2, 4)

        const pos = complete / max * this.bg.width - this.bg.width / 2;
        this.marks.drawPolygon([pos, this.bg.height - 12, pos + 7, this.bg.height, pos - 7, this.bg.height])
        this.marks.endFill();
    }

    private updateTripleTargetIndicator(complete: number[], { max, targets }: DoubleSideTripleTargetCookingData) {
        this.bg.texture = PIXI.Texture.from('progress-big');
        this.gfxMask.texture = PIXI.Texture.from('progress-big-mask');
        this.marks.mask = this.gfxMask;

        this.marks.clear();
        this.marks.beginFill(0xffffff);

        targets.forEach(target => {
            this.marks.drawCircle(target / max * this.bg.width - this.bg.width / 2, this.bg.height / 2, 4)
        })

        const pos1 = complete[0] / max * this.bg.width - this.bg.width / 2;
        this.marks.drawPolygon([pos1, this.bg.height - 12, pos1 + 7, this.bg.height, pos1 - 7, this.bg.height])

        const pos2 = complete[1] / max * this.bg.width - this.bg.width / 2;
        this.marks.drawPolygon([pos2, 12, pos2 + 7, 0, pos2 - 7, 0])

        this.marks.endFill();
    }
}
