import * as PIXI from 'pixi.js';
import { Observable } from 'rxjs';
import { PieIngredient } from '../types';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';

export default class TortObject extends PIXI.Container {


    private pieText = new PIXI.Text('');
    private fruitText = new PIXI.Text('');
    private meringueText = new PIXI.Text('');


    private pie1 = PIXI.Sprite.from('tort/pie1');
    private drop1 = PIXI.Sprite.from('tort/drop1');
    private surface1 = PIXI.Sprite.from('tort/surface1');
    private pie2 = PIXI.Sprite.from('tort/pie2');
    private drop2 = PIXI.Sprite.from('tort/drop2');
    private surface2 = PIXI.Sprite.from('tort/surface2');

    private fruit = new PIXI.Sprite();
    private meringue = new PIXI.Sprite();


    constructor(tortObs: Observable<{ pie: PieIngredient | null, fruit: PieIngredient | null, meringue: PieIngredient | null } | null>) {
        super();

        this.fruitText.position.set(0, 30);
        this.meringueText.position.set(0, 60);

        this.addChild(this.pie1, this.drop1, this.surface1, this.pie2, this.drop2, this.surface2);
        this.addChild(this.fruit);
        this.addChild(this.meringue);

        this.pie1.anchor.set(.5);
        this.pie1.position.set(0, 100);

        this.drop1.anchor.set(.5);
        this.drop1.position.set(0, 50);

        this.surface1.anchor.set(.5);
        this.surface1.position.set(0, 26);

        this.pie2.anchor.set(.5);
        this.pie2.position.set(0, -20);

        this.drop2.anchor.set(.5);
        this.drop2.position.set(0, -60);

        this.surface2.anchor.set(.5);
        this.surface2.position.set(0, -77);

        this.fruit.anchor.set(.5);
        this.fruit.position.set(0, 20);

        this.meringue.anchor.set(.5);
        this.meringue.position.set(0, -115);
        this.meringue.scale.set(1.5);

        // this.addChild(this.pieText);
        // this.addChild(this.fruitText);
        // this.addChild(this.meringueText);

        tortObs.subscribe((data) => {

            if (!data) {
                this.setPie(null);
                this.setFruit(null);
                this.setMerigue(null);
                return;
            }

            const { pie, fruit, meringue } = data;
            if (!pie) {
                this.setPie(null);
                this.setFruit(null);
                this.setMerigue(null);
                return;
            }

            this.setPie(pie);
            this.setFruit(fruit);
            this.setMerigue(meringue);
        })
    }
    setPie(ingr: PieIngredient | null) {
        this.pieText.text = ingr ? ingr.name : '';

        this.pie1.visible = Boolean(ingr);
        this.pie2.visible = Boolean(ingr);
        this.drop1.visible = Boolean(ingr);
        this.drop2.visible = Boolean(ingr);
        this.surface1.visible = Boolean(ingr);
        this.surface2.visible = Boolean(ingr);

        if (ingr) {
            switch (ingr.id) {
                case 'c1':
                    this.pie1.filters = [new ColorOverlayFilter(0xFFD6AB)];
                    this.pie2.filters = [new ColorOverlayFilter(0xFFD6AB)];
                    this.drop1.filters = [new ColorOverlayFilter(0xF8A4C6)];
                    this.drop2.filters = [new ColorOverlayFilter(0xF8A4C6)];
                    this.surface1.filters = [new ColorOverlayFilter(0xF7BBD6)];
                    this.surface2.filters = [new ColorOverlayFilter(0xF7BBD6)];
                    break;

                case 'c2':
                    this.pie1.filters = [new ColorOverlayFilter(0x442406)];
                    this.pie2.filters = [new ColorOverlayFilter(0x442406)];
                    this.drop1.filters = [new ColorOverlayFilter(0x7E470D)];
                    this.drop2.filters = [new ColorOverlayFilter(0x7E470D)];
                    this.surface1.filters = [new ColorOverlayFilter(0xAC6E3A)];
                    this.surface2.filters = [new ColorOverlayFilter(0xAC6E3A)];
                    break;

                case 'c3':
                    this.pie1.filters = [new ColorOverlayFilter(0x442406)];
                    this.pie2.filters = [new ColorOverlayFilter(0x442406)];
                    this.drop1.filters = [new ColorOverlayFilter(0xF8A4C6)];
                    this.drop2.filters = [new ColorOverlayFilter(0xF8A4C6)];
                    this.surface1.filters = [new ColorOverlayFilter(0xF7BBD6)];
                    this.surface2.filters = [new ColorOverlayFilter(0xF7BBD6)];
                    break;

                case 'c4':
                    this.pie1.filters = [new ColorOverlayFilter(0x7E440E)];
                    this.pie2.filters = [new ColorOverlayFilter(0x7E440E)];
                    this.drop1.filters = [new ColorOverlayFilter(0xFFBD54)];
                    this.drop2.filters = [new ColorOverlayFilter(0xFFBD54)];
                    this.surface1.filters = [new ColorOverlayFilter(0xFBDDB1)];
                    this.surface2.filters = [new ColorOverlayFilter(0xFBDDB1)];
                    break;
            }
        }


    }
    setFruit(ingr: PieIngredient | null) {
        this.fruitText.text = ingr ? ingr.name : '';

        this.fruit.visible = Boolean(ingr);

        if (ingr) {
            switch (ingr.id) {
                case 'truskawka':
                    this.fruit.texture = PIXI.Texture.from('tort/truskawki');
                    this.fruit.position.set(0, 20);
                    break;

                case 'malina':
                    this.fruit.texture = PIXI.Texture.from('tort/maliny');
                    this.fruit.position.set(0, 20);
                    break;

                case 'sliwka':
                    this.fruit.texture = PIXI.Texture.from('tort/sliwki');
                    this.fruit.position.set(0, 20);
                    break;

                case 'wisnia':
                    this.fruit.texture = PIXI.Texture.from('tort/wisnie');
                    this.fruit.position.set(0, 28);
                    break;
            }
        }
    }
    setMerigue(ingr: PieIngredient | null) {
        this.meringueText.text = ingr ? ingr.name : '';

        this.meringue.visible = Boolean(ingr);

        if (ingr) {
            switch (ingr.id) {
                case 'm-rozowa':
                    this.meringue.texture = PIXI.Texture.from('tort/rozowy');
                    break;

                case 'm-fioletowa':
                    this.meringue.texture = PIXI.Texture.from('tort/fioletowy');
                    break;

                case 'm-pomaranczowa':
                    this.meringue.texture = PIXI.Texture.from('tort/pomaranczowy');
                    break;
            }
        }
    }
}
