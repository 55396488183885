import * as PIXI from 'pixi.js';
import { Food } from '../type';

export default class ReadyFoodPreview extends PIXI.Container {

    private text = new PIXI.Text('');
    private sprite = new PIXI.Sprite();
    constructor() {
        super();

        // this.addChild(this.text);

        this.sprite.anchor.set(.5);
        this.addChild(this.sprite);
    }

    public setFood(food: Food) {
        this.text.text = food.name;

        switch (food.id) {
            case 'warzywa':
                this.sprite.texture = PIXI.Texture.from('cooking/food/ready/warzywa');
                break;
            case 'kurczak':
                this.sprite.texture = PIXI.Texture.from('cooking/food/ready/kurczak');
                break;
            case 'stek':
                this.sprite.texture = PIXI.Texture.from('cooking/food/ready/stek');
                break;
            case 'zupa':
                this.sprite.texture = PIXI.Texture.from('cooking/food/ready/zupa');
                break;
            case 'sos':
                this.sprite.texture = PIXI.Texture.from('cooking/food/ready/sos');
                break;
        }
    }
}
