import React from "react";

import './style.scss';

type Props = {
    sex: string,
    hair: string,
    clothes: string
}

const AvatarGenerator: React.FC<Props> = ({sex, hair, clothes}) => {
    return <div className="avatar-generator">
        <img src={`/assets/images/avatar/${sex}-${hair}-${clothes}.png`} alt=""/>
    </div>
}

export default AvatarGenerator;
