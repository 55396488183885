import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { getGameRoute } from "../routes";
import AvatarGenerator from "./avatar-generator";

export default function AvatarPage() {

    const { register, watch, handleSubmit } = useForm();
    const history = useHistory();

    const sex = watch('sex', 'k');
    const hair = watch('hair', '1');
    const clothes = watch('clothes', '1');

    const [showPopup, setShowPopup] = useState(true);

    const closePopup = useCallback(() => {
        setShowPopup(false);
    }, [setShowPopup])

    const onSubmit = useCallback(() => {
        history.push(getGameRoute())
    }, [history])

    return <div className="avatar-page">
        <div className="form-column">
            <div className="frame-wrapper">
                <div className="frame">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="header">
                            <h1>Stwórz swoją postać</h1>
                        </div>
                        <div className="form-row portret">
                            <div className="label">Wybierz płeć</div>
                            <label>
                                <input type="radio" name="sex" value="k" defaultChecked={true} ref={register} />
                                <img src="/assets/images/avatar/portret-k.png" alt="" />
                            </label>
                            <label>
                                <input type="radio" name="sex" value="m" ref={register} />
                                <img src="/assets/images/avatar/portret-m.png" alt="" />
                            </label>
                        </div>
                        <div className="form-row color">
                            <div className="label">Kolor włosów</div>
                            <label>
                                <input type="radio" name="hair" value="1" defaultChecked={true} ref={register} />
                                <div className="color-test" style={{background: '#e59c3b'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="hair" value="2" ref={register} />
                                <div className="color-test" style={{background: '#d86038'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="hair" value="3" ref={register} />
                                <div className="color-test" style={{background: '#433d36'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="hair" value="4" ref={register} />
                                <div className="color-test" style={{background: '#000000'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="hair" value="5" ref={register} />
                                <div className="color-test" style={{background: '#542505'}}></div>
                        </label>
                        </div>
                        <div className="form-row color">
                            <div className="label">Kolor stroju</div>
                            <label>
                                <input type="radio" name="clothes" value="1" defaultChecked={true} ref={register} />
                                <div className="color-test" style={{background: '#ffffff'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="clothes" value="2" ref={register} />
                                <div className="color-test" style={{background: '#8cc63f'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="clothes" value="3" ref={register} />
                                <div className="color-test" style={{background: '#de29f3'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="clothes" value="4" ref={register} />
                                <div className="color-test" style={{background: '#4cb3f5'}}></div>
                        </label>
                            <label>
                                <input type="radio" name="clothes" value="5" ref={register} />
                                <div className="color-test" style={{background: '#d20000'}}></div>
                        </label>
                        </div>
                        <div className="form-row name">
                            <div className="label">Wpisz imię</div>
                            <input type="text" name="mane" ref={register} />
                        </div>
                        <div className="footer">
                            <button className="button" type="submit"><span>Przejdź</span> <span>Dalej</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div className="avatar-column">
            <AvatarGenerator
                sex={sex}
                hair={hair}
                clothes={clothes}
            />
        </div>

        {showPopup && <div className="pop-up">
            <div className="pop-up-frame">
                <p>Wasza restauracja jest gotowa na otwarcie! Brakuje już tylko Ciebie. Stwórz swojego avatara i dołącz do swojego zespołu!</p>
                <button className="button" onClick={closePopup}><span>Przejdź</span> <span>Dalej</span></button>
            </div>
        </div>}
    </div>
}
