import * as PIXI from 'pixi.js';
import { Tool } from '../type';

export default class ToolPreview extends PIXI.Container {

    private text = new PIXI.Text('');
    private sprite = new PIXI.Sprite();
    constructor() {
        super();

        this.addChild(this.sprite);
        // this.addChild(this.text);
    }

    public setTool(tool: Tool) {
        this.text.text = tool.name;
        this.sprite.texture = PIXI.Texture.from(tool.texture.id) || PIXI.Texture.EMPTY;
        this.sprite.anchor.set(tool.texture.anchor.x, tool.texture.anchor.y);
    }
}
