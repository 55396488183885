import Cooking from "../game";

const BASE_WIDTH = 1920;
const BASE_HEIGHT = 1080;

export type GameConfig = {
    container: HTMLElement,
    stargingStage: string,
}

export default function createGame(config: GameConfig) {

    const app = new Cooking(BASE_WIDTH, BASE_HEIGHT, 0xfff8e6, config)
    config.container.appendChild(app.view);

    // ====================== //

    return {
        getSceenBlob: () => app.getSceenBlob(),
        uninstall: () => {
            app.destroy();
        }
    };
}
