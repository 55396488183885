import * as PIXI from 'pixi.js';
import { BehaviorSubject, of } from 'rxjs';
import { PieIngredient } from '../types';
import SweetnessMeter from './sweetness-meter';
import TextureMeter from './texture-meter';

export default class TortTooltip extends PIXI.Container {

    private sweetness = new BehaviorSubject<number>(0);
    private texture = new BehaviorSubject<number>(0);

    private icon = new PIXI.Sprite();
    private time = new PIXI.Text('', {
        fill: 0xff0000,
        fontWeight: 'bold'
    } as PIXI.TextStyle);

    constructor() {
        super();

        const bg = PIXI.Sprite.from('tort/tooltip');

        const sweetness = new SweetnessMeter({
            value: this.sweetness,
            goal: of(0),
            size: 25,
            gap: 5
        })

        const texture = new TextureMeter({
            value: this.texture,
            goal: of(0),
            size: 25,
            gap: 5
        })

        sweetness.position.set(195, 155);
        texture.position.set(195, 230);

        this.icon.anchor.set(.5);
        this.icon.position.set(195, 65);

        this.time.anchor.set(1,0);
        this.time.position.set(360, 305);

        this.addChild(bg);
        this.addChild(sweetness);
        this.addChild(texture);
        this.addChild(this.icon);
        this.addChild(this.time);
    }

    setIngredient(ingr: PieIngredient, spriteId: string) {
        this.sweetness.next(ingr.sweetness);
        this.texture.next(ingr.texture);

        this.icon.texture = PIXI.Texture.from(spriteId);
        this.time.text = `${ingr.time}''`
    }
}
