import { BehaviorSubject, combineLatest, interval, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PieIngredient, TortOrder, TortOrderFull, USER_STATE } from "./types";

export default class TortDataStorage {

    constructor() {
        this.initSystems();
    }

    public userState = new BehaviorSubject<USER_STATE>(USER_STATE.SELECTING);
    public orders = new BehaviorSubject<TortOrder[]>([
        {
            id: '1',
            pie: 'c1',
            fruit: 'malina',
            meringue: 'm-rozowa'
        },
        {
            id: '2',
            pie: 'c2',
            fruit: 'wisnia',
            meringue: 'm-fioletowa'
        },
        {
            id: '3',
            pie: 'c4',
            fruit: 'malina',
            meringue: 'm-pomaranczowa'
        }
    ])

    public selectedPie = new BehaviorSubject<PieIngredient | null>(null)
    public selectedFruit = new BehaviorSubject<PieIngredient | null>(null)
    public selectedMeringue = new BehaviorSubject<PieIngredient | null>(null)

    public fullOrders: Observable<TortOrderFull[]> = this.orders
        .pipe(
            map(orders => {
                return orders
                    .map(order => {
                        const pie = this.ingredients.find(i => i.id === order.pie);
                        const fruit = this.ingredients.find(i => i.id === order.fruit);
                        const meringue = this.ingredients.find(i => i.id === order.meringue);

                        return {
                            order,
                            pie,
                            fruit,
                            meringue
                        }
                    })
                    .filter(({ pie, fruit, meringue }) => {
                        return Boolean(pie && fruit && meringue);
                    })
                    .map(({ order, pie, fruit, meringue }) => {
                        if (pie && fruit && meringue) {
                            return {
                                id: order.id,
                                pie,
                                fruit,
                                meringue,
                                sweetness: pie.sweetness + fruit.sweetness + meringue.sweetness,
                                texture: pie.texture + fruit.texture + meringue.texture,
                            }
                        }

                        throw new Error('Something went wrong!');
                    })
            })
        )

    public selectedOrderId = new BehaviorSubject<string | null>(null);

    public selectedOrder = combineLatest([
        this.fullOrders,
        this.selectedOrderId
    ]).pipe(
        map(([orders, selectedOrderId]) => {
            return orders.find(o => o.id === selectedOrderId) || null;
        })
    )

    public selectedTort = combineLatest([
        this.selectedPie,
        this.selectedFruit,
        this.selectedMeringue
    ])
        .pipe(
            map(([pie, fruit, meringue]) => {
                return {
                    pie,
                    fruit,
                    meringue,
                    sweetness: (pie ? pie.sweetness : 0) + (fruit ? fruit.sweetness : 0) + (meringue ? meringue.sweetness : 0),
                    texture: (pie ? pie.texture : 0) + (fruit ? fruit.texture : 0) + (meringue ? meringue.texture : 0),
                    time: (pie ? pie.time : 0) + (fruit ? fruit.time : 0) + (meringue ? meringue.time : 0)
                }
            })
        )

    public gameClock = interval(1000);

    public timeToEnd = combineLatest([
        this.selectedTort,
        this.userState,
        this.gameClock
    ])
        .pipe(
            map(([tort, state]) => {

                if (state === USER_STATE.SELECTING) {
                    return tort.time;
                }

                if (state === USER_STATE.MAKING) {
                    return tort.time - ((Date.now() - this.startMakingTime) / 1000)
                }

                return 0;
            })
        )

    public ingredients: PieIngredient[] = [
        {
            id: 'c1',
            name: 'Ciasto 1',
            sweetness: 1,
            texture: 1,
            time: 5,
            type: 'pie'
        },

        {
            id: 'c2',
            name: 'Ciasto 2',
            sweetness: 1,
            texture: 2,
            time: 10,
            type: 'pie'
        },

        {
            id: 'c3',
            name: 'Ciasto 3',
            sweetness: 3,
            texture: 5,
            time: 15,
            type: 'pie'
        },

        {
            id: 'c4',
            name: 'Ciasto 4',
            sweetness: 2,
            texture: 3,
            time: 10,
            type: 'pie'
        },

        {
            id: 'truskawka',
            name: 'Truskawka',
            sweetness: 1,
            texture: 1,
            time: 5,
            type: 'fruit'
        },

        {
            id: 'malina',
            name: 'Malina',
            sweetness: 2,
            texture: 1,
            time: 10,
            type: 'fruit'
        },

        {
            id: 'wisnia',
            name: 'Wiśnia',
            sweetness: 1,
            texture: 1,
            time: 15,
            type: 'fruit'
        },

        {
            id: 'sliwka',
            name: 'Śliwka',
            sweetness: 0,
            texture: 1,
            time: 10,
            type: 'fruit'
        },

        {
            id: 'm-rozowa',
            name: 'Różowy lukier',
            sweetness: 1,
            texture: 1,
            time: 5,
            type: 'meringue'
        },

        {
            id: 'm-fioletowa',
            name: 'Fioletowy lukier',
            sweetness: 3,
            texture: 2,
            time: 10,
            type: 'meringue'
        },

        {
            id: 'm-pomaranczowa',
            name: 'Pomaranczowy lukier',
            sweetness: 2,
            texture: 1,
            time: 15,
            type: 'meringue'
        }
    ];


    public isReadyToMake = combineLatest([
        this.selectedTort,
        this.selectedOrder
    ]).pipe(
        map(([tort, order]) => {
            // return Boolean(tort.pie) && Boolean(tort.fruit) && Boolean(tort.meringue) && order && tort.sweetness >= order?.sweetness && tort.texture >= order.texture;
            return Boolean(tort.pie) && Boolean(tort.fruit) && Boolean(tort.meringue);
        })
    );

    public isReadyToServe = combineLatest([
        this.selectedTort,
        this.selectedOrder,
        this.userState
    ]).pipe(
        map(([tort, order, state]) => {
            return (state === USER_STATE.COMPLETE) && Boolean(tort.pie) && Boolean(tort.fruit) && Boolean(tort.meringue) && order && tort.sweetness >= order?.sweetness && tort.texture >= order.texture;
        })
    );


    public selectOrder(id: string) {
        if (this.userState.value === USER_STATE.SELECTING) {
            this.selectedOrderId.next(id);
        }
    }

    public setIngredient(id: string) {

        if (this.userState.value === USER_STATE.SELECTING) {
            const ingr = this.ingredients.find(i => i.id === id);

            if (ingr) {
                switch (ingr.type) {
                    case 'pie':
                        this.selectedPie.next(ingr);
                        break;

                    case 'fruit':
                        this.selectedFruit.next(ingr);
                        break;

                    case 'meringue':
                        this.selectedMeringue.next(ingr);
                        break;
                }
            }
        }
    }

    private startMakingTime: number = 0;

    public startMaking() {
        this.startMakingTime = Date.now();
        this.userState.next(USER_STATE.MAKING);
    }

    public reset() {
        this.selectedPie.next(null);
        this.selectedFruit.next(null);
        this.selectedMeringue.next(null);
        this.selectedOrderId.next(null);

        this.userState.next(USER_STATE.SELECTING);
    }

    public serve() {

        this.orders.next(this.orders.value.filter(o => o.id !== this.selectedOrderId.value))

        this.reset()
    }

    private initSystems() {
        combineLatest([
            this.userState,
            this.timeToEnd
        ]).subscribe(([state, time]) => {
            if (state === USER_STATE.MAKING && time <= 0) {
                this.userState.next(USER_STATE.COMPLETE);
            }
        })
    }
}
