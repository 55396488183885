import * as PIXI from 'pixi.js';
import { Observable } from 'rxjs';
import { Order } from '../type';
import OrderObject from './order';

export default class OrderList extends PIXI.Container {


    private orderLayout = [
        [0, 0], [140, 0], [280, 0]
    ];

    private orders: { order: Order, object: OrderObject }[] = [
    ];

    constructor(private orderObs: Observable<Order[]>) {
        super();

        orderObs.subscribe((orders) => {

            this.orders
                .filter(o => !orders.find(o2 => o2.id === o.order.id))
                .forEach(o => {
                    o.object.destroy();

                    this.orders.splice(this.orders.indexOf(o), 1);
                })

            orders.forEach((order) => {
                const existing = this.orders.find(o => o.order.id === order.id);

                if (!existing) {

                    const texture = (() => {
                        switch (order.foodId) {
                            case 'warzywa':
                                return PIXI.Texture.from('order-warzywa');
                            case 'sos':
                                return PIXI.Texture.from('order-sos');
                            case 'zupa':
                                return PIXI.Texture.from('order-zupa');
                            case 'kurczak':
                                return PIXI.Texture.from('order-kurczak');
                            case 'stek':
                                switch (order.completeGoal) {
                                    case 17:
                                        return PIXI.Texture.from('order-stek-rare');
                                    case 50:
                                        return PIXI.Texture.from('order-stek-medium');
                                    case 83:
                                        return PIXI.Texture.from('order-stek-well');
                                }
                                return PIXI.Texture.EMPTY;

                            default:
                                return PIXI.Texture.EMPTY;
                        }
                    })();


                    const newOrder = new OrderObject(texture);
                    this.addChild(newOrder);
                    this.orders.push({ order, object: newOrder });
                }
            });

            this.orders.forEach((order, index) => {
                order.object.position.set(...this.orderLayout[index]);
            })
        });
    }
}

