import React, { useCallback, useEffect, useRef, useState } from 'react';
import CookingGame from '../../components/cooking-game';

import './style.scss';

export default function GamePage() {

    const page = useRef<HTMLDivElement>(null);
    const [centerStyle, setCenterStyle] = useState<'row' | 'column'>('row');

    const resize = useCallback(() => {
        if (page.current) {
            const w = page.current.offsetWidth;
            const h = page.current.offsetHeight;

            if (h/w > .56) {
                setCenterStyle('row');
            } else {
                setCenterStyle('column');
            }
        }
    }, [page])

    useEffect(() => {

        window.setInterval(() => {
            resize();
        }, 0)
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [resize])

    return <div className={`game-page ${centerStyle}`} ref={page}>
        <div className="wrapper">
            <div className="container">
                <CookingGame stage="avatar" />
            </div>
        </div>
    </div>
}
