import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AvatarPage from './routes/avatar';
import CharacterPage from './routes/character';
import CookingDemo from './routes/demo/cooking';
import TortDemo from './routes/demo/tort';
import GamePage from './routes/game';
import GoalsPage from './routes/goals';

import HomePage from './routes/home';
import IntroPage from './routes/intro';
import { getAvatarRoute, getCharacterRoute, getGameRoute, getGoalsRoute, getHomeRoute, getIntroRoute, getRulesRoute } from './routes/routes';
import RulesPage from './routes/rules';

import './style.scss';
export default function App() {

    const isLogged = true;

    return (
        <BrowserRouter>
            <Switch>
                <Route path={getHomeRoute()} component={GamePage} exact />
                <Route path={getIntroRoute()} component={IntroPage} />
                <Route path={getCharacterRoute()} component={CharacterPage} />
                <Route path={getRulesRoute()} component={RulesPage} />
                <Route path={getAvatarRoute()} component={AvatarPage} />
                {isLogged && <Route path={getGoalsRoute()} component={GoalsPage} />}
                {isLogged && <Route path={getGameRoute()} component={GamePage} />}

                <Route path={'/demo/tort'} component={TortDemo} />
                <Route path={'/demo/cooking'} component={CookingDemo} />

                <Route component={HomePage} />
            </Switch>
        </BrowserRouter>
    )
}
