import * as PIXI from 'pixi.js';
import { TortOrderFull } from "../types";
import { Observable, of } from 'rxjs';
import TortObject from './tort';
import SweetnessMeter from './sweetness-meter';
import TextureMeter from './texture-meter';

export default class OrderObject extends PIXI.Container {


    private paper = PIXI.Sprite.from('tort/order');
    private bg = new PIXI.Graphics();
    constructor(data: TortOrderFull, selectedIdObs: Observable<boolean>) {
        super();

        this.interactive = true;
        this.cursor = 'pointer';

        this.width = 400;

        this.addChild(this.bg);
        this.addChild(this.paper);

        const icon = new TortObject(of({
            pie: data.pie,
            fruit: data.fruit,
            meringue: data.meringue
        }))

        icon.scale.set(.3);
        icon.position.set(this.width / 2, this.height * .40);
        this.addChild(icon);


        const sweetness = new SweetnessMeter({
            value: of(data.sweetness),
            goal: of(0),
            size: 16,
            gap: 3
        });
        const texture = new TextureMeter({
            value: of(data.texture),
            goal: of(0),
            size: 16,
            gap: 3
        });


        sweetness.position.set(this.width * .2, this.height * .70);
        this.addChild(sweetness);

        texture.position.set(this.width * .2, this.height * .80);
        this.addChild(texture);


        selectedIdObs.subscribe(v => {
            this.bg.clear();
            this.bg.beginFill(v ? 0xff0000 : 0x4e4f50);
            this.bg.drawRoundedRect(-2, -2, 184, 250, 4);
            this.bg.endFill();
        })
    }
}
