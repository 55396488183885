import React from "react";
import { Link } from "react-router-dom";
import CookingGame from "../../components/cooking-game";

const TortDemo: React.FC = () => {
    return (
        <div style={{width: '80%', position: 'relative'}}>
            <Link to="/demo/cooking">COOKING</Link>
            <CookingGame stage="tort" />
        </div>
    )
}

export default TortDemo;
