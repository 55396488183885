import * as PIXI from 'pixi.js';
import GameStage from '../game-stage';

export default class GameGoalsScreen extends GameStage {
    constructor() {
        super();

        this.loadAssets([
            ['goals', '/assets/goals/goals.json']
        ]).then(() => {
            const sprite = PIXI.Sprite.from('goals/full');
            this.addChild(sprite);

            const button = new PIXI.Sprite(PIXI.Texture.EMPTY);

            button.width = 266;
            button.height = 51;

            button.position.set(827,933);

            button.cursor = 'pointer'
            button.interactive = true;
            button.on('pointerdown', () => {
                this.emit('go-to-next');
            });

            this.addChild(button);

        })
    }
}
